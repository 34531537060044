import { AxiosResponse } from 'axios';
import {
  fork,
  put,
  select, takeLatest,
} from 'redux-saga/effects';
import { downloadCsv, getHistory, patchAnswers } from 'services/fieldNotebookService';
import { generateUpdateAnswersData } from '../forms/utils';
import { showSnackbar } from '../snackbar/actions';
import {
  getHistoricsFailure,
  getHistoricsSuccess,
  sendUpdateAnswerError, sendUpdateAnswerSuccess,
  updateHistorics,
} from './actions';
import { Historic, HistoricsActionType } from './types';

function* getHistorics() {
  try {
    const { jwt } = yield select((state) => state.user);
    const { externalId } = yield select((state) => state.organization);
    const { pageNumber, filterOptions } = yield select((state) => state.historics);
    const { form_id: formId } = yield select((state) => state.forms.selectedForm);

    const response: AxiosResponse<Historic[]> = yield getHistory({
      externalId,
      jwt,
      formId,
      pageNumber,
      initialDate: filterOptions.initialDate,
      finalDate: filterOptions.finalDate,
      search: filterOptions.search,
      perPage: 3,
    });

    const hasNextPage = response.data.length !== 0;

    yield put(getHistoricsSuccess(response.data, hasNextPage));
  } catch (error) {
    yield put(getHistoricsFailure());
  }
}

function* getAllHistorics() {
  try {
    const { jwt } = yield select((state) => state.user);
    const { externalId } = yield select((state) => state.organization);
    const { form_id: formId } = yield select((state) => state.forms.selectedForm);
    const { filterOptions } = yield select((state) => state.historics);

    const response: AxiosResponse<Historic[]> = yield downloadCsv({
      externalId,
      jwt,
      formId,
      search: filterOptions.search,
    });

    yield put(getHistoricsSuccess(response.data, false));
  } catch (error) {
    console.log('getHistorics error', error);
    yield put(getHistoricsFailure());
  }
}

function* updateAnswers(action:any) {
  const { successMessage, errorMessage } = action.payload;
  try {
    const { jwt } = yield select((state) => state.user);
    const { answers } = yield select((state) => state.forms.fillData);
    const { answers: historicAnswers } = yield select((state) => state.historics.selectedHistoric);
    const dataRequest = generateUpdateAnswersData(answers, historicAnswers);

    const { status } = yield patchAnswers(
      dataRequest,
      jwt,
    );

    if (status >= 200 && status < 300) {
      yield put(updateHistorics(answers));
      yield put(sendUpdateAnswerSuccess());
      yield put(showSnackbar({ message: successMessage, typeMessage: 'success' }));
    } else {
      yield put(sendUpdateAnswerError());
      yield put(showSnackbar({ message: errorMessage, typeMessage: 'error' }));
    }
  } catch (error) {
    yield put(sendUpdateAnswerError());
    yield put(showSnackbar({ message: errorMessage, typeMessage: 'error' }));
  }
}

function* watchGetHistoricsRequest() {
  yield takeLatest(HistoricsActionType.GET_HISTORICS_REQUEST, getHistorics);
}

function* watchGetAllHistoricsRequest() {
  yield takeLatest(HistoricsActionType.GET_ALL_HISTORICS_REQUEST, getAllHistorics);
}

function* watchUpdateAnswersRequest() {
  yield takeLatest(HistoricsActionType.UPDATE_ANSWERS_REQUEST, updateAnswers);
}

export const historicSagas = [
  fork(watchGetHistoricsRequest),
  fork(watchUpdateAnswersRequest),
  fork(watchGetAllHistoricsRequest),
];
