import {
  UserActionType, User,
} from './types';

export function setToken(token: string) {
  return {
    type: UserActionType.SET_TOKEN,
    payload: { token },
  };
}

export const setUserProperties = (user : User) => (
  {
    type: UserActionType.SET_USER_PROPERTIES,
    payload: {
      user,
    },
  }
);

export const setUserLanguage = (language : string) => (
  {
    type: UserActionType.SET_USER_LANGUAGE,
    payload: {
      language,
    },
  }
);
