import { encode } from 'base64-arraybuffer';

export async function downloadImageFromUrl(imageUrl: string) {
  fetch(imageUrl, {
    method: 'GET',
    headers: {
      pragma: 'no-cache',
      'cache-control': 'no-cache',
    },
  })
    .then((response) => {
      response.arrayBuffer().then((buffer) => {
        const url = window.URL.createObjectURL(new Blob([buffer]));
        const link = document.createElement('a');
        link.href = url;
        link.download = 'image.jpg';
        document.body.appendChild(link);
        link.click();
      });
    })
    .catch((err) => {
      console.log(err);
    });
}

export async function getBase64FromImageUrl(imageUrl: string) {
  const response = await fetch(imageUrl, {
    method: 'GET',
    headers: {
      pragma: 'no-cache',
      'cache-control': 'no-cache',
    },
  });
  const imageBuffer = await response.arrayBuffer();
  const base64String = encode(imageBuffer);
  return `data:image/png;base64,${base64String}`;
}
