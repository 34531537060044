import { Form } from 'store/states/forms/types';

export enum ArchivesActionTypes {
  GET_ARCHIVES_REQUEST = '@archives/GET_ARCHIVES_REQUEST',
  GET_ARCHIVES_SUCCESS = '@archives/GET_ARCHIVES_SUCCESS',
  GET_ARCHIVES_FAILURE = '@archives/GET_ARCHIVES_FAILURE',
  SET_ARCHIVE_SELECTED = '@archives/SET_ARCHIVE_SELECTED',
}
export interface ArchiveState {
  archives: Archive[];
  selectedArchive: Archive;
  success: boolean;
  loading: boolean;
  error: boolean;
}

export interface Archive {
  id: number;
  name: string;
  forms: Form[];
  description: string;
}
