import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { themeOptions } from 'assets/styles/theme';

export const ArchiveName = styled(Typography)`
  color: ${themeOptions.palette.common.black};
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 16px;
`;
