import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

export const LogoWrapper = styled(Box)`
  height: 50px;

  img {
    height: 100%;
  }
`;
