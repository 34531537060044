import React, { useRef, useState, useEffect } from 'react';
import {
  CreateOutlined, CheckCircleOutline,
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { ImperativeModalHandler, Modal } from 'components/Modal/Modal';
import { Button } from 'pages/FieldNotebook/components/Button/Button';
import {
  DrawContainer,
  UserDrawModalButtons,
  UserDrawPreview,
  UserDraw,
  UserDrawToolbar,
} from './styles';
import { ColorOptions, DrawColorsMenu } from './DrawColors';
import { DrawMenu } from './DrawMenu';

interface IDraw {
  setValue: (value: string) => void;
  defaultValue: string;
}

export default function Draw({ defaultValue, setValue }: IDraw) {
  const [userDraw, setUserDraw] = useState<any>(null);
  const [drawPreview, setDrawPreview] = useState('');
  const [drawColor, setDrawColor] = useState(ColorOptions[1].color);

  useEffect(() => {
    setDrawPreview(defaultValue);
  }, [defaultValue]);
  const { t } = useTranslation();

  const drawModalRef = useRef<ImperativeModalHandler>(null);

  const handleOpenUserDrawModal = () => drawModalRef.current?.open();
  const handleCloseUserDrawModal = () => drawModalRef.current?.close();

  const saveUserDraw = async (draw: any) => {
    setValue(draw?.getDataURL());
    handleCloseUserDrawModal();
  };

  return (
    <>
      <DrawContainer>
        {drawPreview && <UserDrawPreview src={defaultValue} />}
        <Button
          variant='outlined'
          color='primary'
          onClick={() => handleOpenUserDrawModal()}
          rightIcon={<CreateOutlined />}
        >
          {t('draw_modal_title')}
        </Button>
      </DrawContainer>
      <Modal
        ref={drawModalRef}
        title={t('draw_modal_title')}
        subtitle={t('draw_modal_subtitle')}
      >
        <UserDraw
          ref={(draw: any) => setUserDraw(draw)}
          brushRadius={3}
          brushColor={drawColor}
          hideGrid
        />
        <UserDrawToolbar>
          <DrawColorsMenu drawColor={drawColor} setDrawColor={setDrawColor} />
          <DrawMenu draw={userDraw} />
        </UserDrawToolbar>
        <UserDrawModalButtons>
          <Button onClick={() => handleCloseUserDrawModal()}>
            {t('back')}
          </Button>
          <Button
            onClick={() => saveUserDraw(userDraw)}
            variant='contained'
            rightIcon={<CheckCircleOutline />}
          >
            {t('finalize')}
          </Button>
        </UserDrawModalButtons>
      </Modal>
    </>
  );
}
