import sub from 'date-fns/sub';

export interface dateFilter {
  initialDate: string;
  finalDate: string;
}

export interface dateFilterParams {
  type: string;
  initialDate?: string;
  finalDate?: string;
}

export function filterPerDate({ type, initialDate: selectedInitialDate, finalDate: selectedFinalDate }: dateFilterParams): dateFilter {
  const dayDate = new Date();
  let formatedInitialDate : Date | undefined;
  let formatedFinalDate : Date | undefined;
  switch (type) {
    case 'last_seven_days':
    {
      formatedInitialDate = sub(dayDate, { days: 7 });
      break;
    }
    case 'last_fifteen_days':
    {
      formatedInitialDate = sub(dayDate, { days: 15 });
      break;
    }
    case 'last_thirty_days':
    {
      formatedInitialDate = sub(dayDate, { days: 30 });
      break;
    }
    default:
      if (selectedInitialDate && selectedFinalDate) {
        formatedInitialDate = new Date(parseInt(selectedInitialDate, 10));
        formatedFinalDate = new Date(parseInt(selectedFinalDate, 10));
      }
  }

  return {
    initialDate: formatedInitialDate?.toLocaleDateString('pt').replace(/\//g, '-') || '',
    finalDate: formatedFinalDate?.toLocaleDateString('pt').replace(/\//g, '-') || dayDate.toLocaleDateString('pt').replace(/\//g, '-'),
  };
}
