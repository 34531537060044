import React, { useState } from 'react';
import {
  Fab, Menu, MenuItem, Typography,
} from '@mui/material';
import { ColorLens } from '@mui/icons-material';
import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg';
import { ReactComponent as UndoIcon } from 'assets/icons/undo.svg';

interface IDrawMenu {
  draw: any;
}

export const DrawMenu = ({ draw }: IDrawMenu) => {
  const [drawMenu, setDrawMenu] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setDrawMenu(true);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setDrawMenu(false);
  };

  return (
    <>
      <Fab color='primary' size='large' onClick={handleClick}>
        <ColorLens />
      </Fab>
      <Menu
        anchorEl={anchorEl}
        open={drawMenu}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
      >
        <MenuItem onClick={() => draw.eraseAll()}>
          <DeleteIcon />
          <Typography variant='subtitle1'> Apagar tudo</Typography>
        </MenuItem>
        <MenuItem onClick={() => draw.undo()}>
          <UndoIcon />
          <Typography variant='subtitle1'> Desfazer</Typography>
        </MenuItem>
      </Menu>
    </>
  );
};
