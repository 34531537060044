import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { themeOptions } from 'assets/styles/theme';

export const Title = styled(Typography)`
  color: ${themeOptions.palette?.text?.primary};
  font-size: 21px;
  font-weight: ${themeOptions.typography.fontWeightMedium};
  margin: 16px 0 32px;
`;
