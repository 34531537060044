import {
  Divider,
  styled,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
} from '@mui/material';
import { themeOptions } from 'assets/styles/theme';
import React from 'react';
import { List, ListRowRenderer } from 'react-virtualized';

interface RadioBoxProps {

    setValue: (value: string) => void;
    defaultValue: string;
    items: {
      value: string;
      label: string;
    }[]
}

const StyledFomControlLabel = styled(FormControlLabel)`
  color: ${themeOptions.palette?.text?.primary};  
`;

export default function RadioBox(
  {
    items,
    setValue,
    defaultValue,
  }: RadioBoxProps,
) {
  const arrayLastItem = items && items.length - 1;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
  };

  const rowRenderer: ListRowRenderer = ({ index, key, style }) => (
    <div key={key} style={style}>
      <StyledFomControlLabel
        value={items[index].value}
        control={<Radio />}
        label={items[index].label}
      />
      {index !== arrayLastItem && <Divider />}
    </div>
  );

  return (
    <FormControl sx={{ width: 'inherit' }} component='fieldset'>
      <RadioGroup
        onChange={handleChange}
        value={defaultValue}
        name='radio-buttons-group'
      >
        <List
          width={400}
          height={200}
          overscanRowCount={3}
          rowCount={items.length}
          rowHeight={40}
          rowRenderer={rowRenderer}

        />
      </RadioGroup>
    </FormControl>
  );
}

/* {items && items.map((option, index) => (
  <div key={index} />
))} */
