import styled, { keyframes } from 'styled-components';

const keyFrameLoading = keyframes`
  0% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
`;

export const LoadingSkeleton = styled.div`
  background: #ecebeb;
  border-radius: 6px;
  min-width: 385px;
  height: 85px;
  animation: ${keyFrameLoading} 700ms infinite alternate;
`;

interface CardsLoadingProps {
  totalItems: number;
}

export function CardsLoading({ totalItems }: CardsLoadingProps) {
  const items = [];

  for (let i = 0; i < totalItems; i++) {
    items.push(<LoadingSkeleton key={i} />);
  }

  return <>{items.map((item) => item)}</>;
}
