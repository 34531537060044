import { useRef } from 'react';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { themeOptions } from 'assets/styles/theme';
import { ImperativeModalHandler, Modal } from 'components/Modal/Modal';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import { Text, Container } from './styles';
/* eslint-disable jsx-a11y/iframe-has-title */
interface MapsModalProps {
    lat: string;
    long: string;
    subtitle: string;
}

export function MapsAnswer({ lat, long, subtitle }: MapsModalProps) {
  const { t } = useTranslation();

  const modalRef = useRef<ImperativeModalHandler>(null);
  const handleOpenModal = () => modalRef.current?.open();
  const handleCloseModal = () => modalRef.current?.close();

  return (
    <>
      <Text
        onClick={handleOpenModal}
      >
        {`${parseFloat(lat).toFixed(7)}, ${parseFloat(long).toFixed(7)}`}
        <LocationOnIcon style={{ color: themeOptions.palette.primary.main }} />
      </Text>

      <Modal ref={modalRef} title={t('localization_of_your_note')} subtitle={subtitle} hasCloseIcon>
        <Container>
          <iframe
            width='100%'
            height='100%'
            id='gmap_canvas'
            src={`https://maps.google.com/maps?q=${lat},%20${long}&t=&z=14&ie=UTF8&iwloc=&output=embed`}
            frameBorder='0'
            scrolling='yes'
          />
        </Container>
      </Modal>
    </>
  );
}
