import styled from 'styled-components';
import { themeOptions } from 'assets/styles/theme';

interface InputProps {
  color?: string;
  weight?: number;
}

export const Input = styled.p<InputProps>`
  color: ${(props) => props.color || themeOptions.palette.common.black};
  font-size: 15px;
  font-weight: ${(props) => props.weight || 500};
`;
