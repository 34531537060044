import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Divider,
  Grid,
  IconButton,
  List,
  SwipeableDrawer,
} from '@mui/material';

import {
  Menu as MenuIcon,
} from '@mui/icons-material';
import Logo from 'components/Logo/Logo';
import { useSelector } from 'react-redux';
import { ReduxState } from 'store';
import { menuOptions } from './menuOptions';
import {
  ChevronLeftIcon,
  LinkLogo,
  ListItemTextStylized,
  ListItemStylized,
  MenuText,
} from './styles';

interface ClosedSideBarProps {
  setOpen: (open: boolean) => void;
}
interface OpenSideBarProps {
  setOpen: (open: boolean) => void;
  isOpen: boolean;
}

const V4_URL = process.env.REACT_APP_V4_BASE_URL;

const ClosedSideBar = ({ setOpen } : ClosedSideBarProps) => (
  <Grid justifyItems='center'>
    <IconButton
      onClick={() => setOpen(true)}
      aria-label='menu'
    >
      <MenuIcon />
    </IconButton>
    <MenuText>
      Menu
    </MenuText>
  </Grid>
);

const OpenSideBar = ({ setOpen, isOpen } : OpenSideBarProps) => {
  const orgId = useSelector<ReduxState, number>((state) => state.organization.orgId);
  const { t } = useTranslation();

  const handlerSelectProduct = (path: string) => {
    window.location.href = `${V4_URL}/v4/o/${orgId}/${path}`;
  };

  return (
    <SwipeableDrawer
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={isOpen}
    >
      <Grid container padding={1}>
        <Grid item xs={10} marginRight={1}>
          <LinkLogo
            href={`${V4_URL}/v4/o/${orgId}/maps`}
          >
            <Logo />
          </LinkLogo>
        </Grid>
        <Grid item xs={1} display='flex' justifyItems='center'>
          <IconButton
            onClick={() => setOpen(false)}
          >
            <ChevronLeftIcon />
          </IconButton>
        </Grid>
      </Grid>

      <Divider />

      <List>
        {
            menuOptions.map((option) => (
              !option.hide
              && (
              <ListItemStylized
                title={option.text}
                key={option.text}
                onClick={() => handlerSelectProduct(option.path)}
                button
              >
                <span style={{
                  WebkitMask: `url(${option.icon}) no-repeat center`,
                  mask: `url(${option.icon}) no-repeat center`,
                  background: '#393939',
                  marginRight: '1rem',
                  height: 23,
                  width: 22,
                }}
                />
                <ListItemTextStylized
                  primary={t(option.key)}
                />
              </ListItemStylized>
              )
            ))
          }
      </List>
    </SwipeableDrawer>
  );
};

export const SideBar = () => {
  const [open, setOpen] = useState(false);

  return (
    <>
      {open ? <OpenSideBar setOpen={setOpen} isOpen={open} /> : <ClosedSideBar setOpen={setOpen} />}
    </>
  );
};

export default SideBar;
