import { AxiosResponse } from 'axios';
import {
  takeEvery, put, fork, select,
} from 'redux-saga/effects';
import { getFieldNotebooks } from 'services/fieldNotebookService';
import { formatArchives } from 'utils/fieldNotebookUtils/formatters';
import { getArchivesFailure, getArchivesSuccess } from './actions';
import { ArchiveState, ArchivesActionTypes } from './types';

function* getArchives() {
  try {
    const { jwt } = yield select((state) => state.user);
    const { externalId } = yield select((state) => state.organization);

    const response: AxiosResponse<ArchiveState> = yield getFieldNotebooks(
      {
        externalId,
        jwt,
      },
    );

    const archivesFormated = formatArchives(response.data.archives);
    yield put(getArchivesSuccess(archivesFormated));
  } catch (error) {
    yield put(getArchivesFailure());
  }
}

function* watchGetArchivesRequest() {
  yield takeEvery(ArchivesActionTypes.GET_ARCHIVES_REQUEST, getArchives);
}

export const archiveSagas = [fork(watchGetArchivesRequest)];
