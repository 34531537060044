import { ProgressBar } from 'components/ProgressBar/ProgressBar';
import { useTranslation } from 'react-i18next';
import { Wrapper } from './styles';

interface ProgressBarProps {
  totalSteps: number;
  step: number;
}

export function StepProgressBar({ step, totalSteps }: ProgressBarProps) {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <ProgressBar
        step={step}
        totalSteps={totalSteps}
      />
    </Wrapper>
  );
}
