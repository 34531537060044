import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import 'config/i18n';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@mui/material';
import { PersistGate } from 'redux-persist/integration/react';

import { themeOptions } from 'assets/styles/theme';
import { startTracking } from 'config/amplitude';
import { store, persistor } from './store';
import App from './App';
import reportWebVitals from './reportWebVitals';

startTracking();

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={themeOptions}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Suspense fallback={<div>Loading...</div>}>
            <App />
          </Suspense>
        </PersistGate>
      </Provider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

reportWebVitals();
