import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Button } from 'pages/FieldNotebook/components/Button/Button';
import { useDispatch } from 'react-redux';
import { editAnswer } from 'store/states/forms/actions';
import { Field } from 'store/states/forms/types';
import React from 'react';
import { ImperativeModalHandler, Modal } from 'components/Modal/Modal';
import { Buttons } from './styles';

interface RulesNoteProps {
  modalRef: React.RefObject<ImperativeModalHandler>;
  handleEdit: () => void;
}

export function RulesModal({ modalRef, handleEdit }: RulesNoteProps) {
  const { t } = useTranslation();

  const handleCloseRulesModal = () => modalRef.current?.close();

  const cancelModal = () => {
    handleCloseRulesModal();
  };

  return (
    <Modal ref={modalRef} title={t('rules_modal_title')} subtitle={t('rules_modal_subtitle')}>
      <Buttons>
        <Button onClick={handleEdit}>{t('fill_informations')}</Button>
        <Button
          variant='contained'
          color='error'
          onClick={cancelModal}
        >
          {t('cancel')}
        </Button>
      </Buttons>
    </Modal>
  );
}
