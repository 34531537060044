import { Item } from '../forms/types';

export enum OrganizationActionType {
  GET_ORGANIZATION_REQUEST = '@organization/GET_ORGANIZATION_REQUEST',
  GET_ORGANIZATION_SUCCESS = '@organization/GET_ORGANIZATION_SUCCESS',
  GET_ORGANIZATION_FAILURE = '@organization/GET_ORGANIZATION_FAILURE',
  SET_ORGANIZATION_IDS = '@organization/SET_ORGANIZATION_IDS',
}

export interface OrganizationState {
  organization: Organization;
  externalId: number;
  orgId: number;
  success: boolean;
  loading: boolean;
  error: boolean;
}

export interface Organization {
  id: number;
  kind: string;
  name: string;
  kind_text: string;
  predominant_culture: string;
  predominant_culture_text: string;
  timezone: string;
  tenant_id: number;
  areas: Item[];
  chemicals: Item[];
  fertilizers: Item[];
  cultivars: Item[];
  tractors: Item[];
  implements: Item[];
  seasons: Item[];
}
