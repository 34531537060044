import { HistoricAnswer } from '../historics/types';
import { Organization } from '../organization/types';
import {
  Answer, AnswerEdit, Field, Form, FormsActionTypes,
} from './types';

export const setSelectedForm = (form: Form, organization : Organization) => (
  {
    type: FormsActionTypes.SET_SELECTED_FORM,
    payload: { form, organization },
  }
);

export const nextStep = (answer: Answer) => (
  {
    type: FormsActionTypes.NEXT_STEP,
    payload: { answer },
  }
);
export const nextStepSuccess = (answer: Answer, nextFieldIndex: number, answerIndex: number, notHasLastQuestion: boolean) => (
  {
    type: FormsActionTypes.NEXT_STEP_SUCCESS,
    payload: {
      answer, nextFieldIndex, answerIndex, notHasLastQuestion,
    },
  }
);
export const previousStep = () => (
  {
    type: FormsActionTypes.PREVIOUS_STEP,
  }
);

 interface sendFormRequestParams {
  successMessage: string;
  errorMessage: string;
 }
export const sendFormRequest = ({ successMessage, errorMessage }: sendFormRequestParams) => (
  {
    type: FormsActionTypes.SEND_FORM_REQUEST,
    payload: {
      successMessage,
      errorMessage,
    },
  }
);

export const sendFormSuccess = () => (
  {
    type: FormsActionTypes.SEND_FORM_SUCCESS,
  }
);

export const sendFormError = () => (
  {
    type: FormsActionTypes.SEND_FORM_ERROR,
  }
);

export const setAnswers = (answers : HistoricAnswer[]) => (
  {
    type: FormsActionTypes.SET_ANSWERS,
    payload: { answers },
  }
);

export const editAnswer = (field: Field) => (
  {
    type: FormsActionTypes.EDIT_ANSWER,
    payload: { field },
  }
);

export const setEditParams = (step: number, fieldStack: number[]) => (
  {
    type: FormsActionTypes.SET_EDIT_PARAMS,
    payload: { step, fieldStack },
  }
);

export const setNewAnswer = (field_id: number, value: string) => (
  {
    type: FormsActionTypes.UPDATE_ANSWER,
    payload: { field_id, value },
  }
);

export const clearEditedAnswers = () => (
  {
    type: FormsActionTypes.CLEAR_EDITED_ANSWERS,
  }
);

export const clearAnswersWhenEditAConditionalAnswer = (startIndex: number) => (
  {
    type: FormsActionTypes.CLEAR_INVALID_ANSWERS,
    payload: { startIndex },
  }
);
