import { fieldNotebookClient } from 'api/fieldNotebookClient';
import { AxiosResponse } from 'axios';
import { AnswerUpdateRequestData } from 'store/states/forms/types';
import { DefaultParams } from 'types/types';

interface PostAnswerFormParams {
  data: any;
  jwt: string;
  externalId: string;
}

export function* getFieldNotebooks({ externalId, jwt }: DefaultParams): any {
  const url = 'field_notebook/company_forms';
  const params = { company_id: externalId };
  const headers = { Authorization: `Bearer ${jwt}` };
  const response = yield fieldNotebookClient.get(url, { params, headers });

  return response;
}

interface getHistoryOptions {
  externalId: string;
  jwt: string;
  formId: number;
  pageNumber?: number;
  perPage?: number;
  initialDate?: string;
  finalDate?: string;
  search?: string;
}

export function* downloadCsv({
  externalId,
  jwt,
  formId,
  pageNumber,
  perPage,
  initialDate,
  finalDate,
  search,
}: getHistoryOptions) {
  const headers = { Authorization: `Bearer ${jwt}` };
  const url = `field_notebook/download_csv?company_id=${externalId}`;
  const response : AxiosResponse = yield fieldNotebookClient.get(
    url,
    {
      headers,
      params: {
        form_id: formId,
        page: pageNumber,
        per_page: perPage,
        initial_date: initialDate,
        final_date: finalDate,
        search,
      },
    },
  );

  return response;
}

export function* getHistory({
  externalId,
  jwt,
  formId,
  pageNumber,
  perPage,
  initialDate,
  finalDate,
  search,
}: getHistoryOptions) {
  const headers = { Authorization: `Bearer ${jwt}` };
  const url = `field_notebook/archive_history?company_id=${externalId}`;
  const response : AxiosResponse = yield fieldNotebookClient.get(
    url,
    {
      headers,
      params: {
        form_id: formId,
        page: pageNumber,
        per_page: perPage,
        initial_date: initialDate,
        final_date: finalDate,
        search,
      },
    },
  );

  return response;
}

export function* postAnswerForm({ jwt, data, externalId }: PostAnswerFormParams) {
  const headers = { Authorization: `Bearer ${jwt}` };
  const url = `/field_notebook/form_answers?company_id=${externalId}`;

  const response: AxiosResponse = yield fieldNotebookClient.post(
    url,
    data,
    { headers },
  );

  return response;
}

export function* patchAnswers(data : AnswerUpdateRequestData, jwt : string) {
  const headers = { Authorization: `Bearer ${jwt}` };
  const url = '/field_notebook/form_answer/update_all';

  const response: AxiosResponse = yield fieldNotebookClient.patch(
    url,
    data,
    { headers },
  );

  return response;
}
