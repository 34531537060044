import { Answer, Field } from 'store/states/forms/types';
import { FieldBoxWithImage } from './FieldBoxWithImage';
import { FieldBox } from './FieldBox';

interface IFieldBox {
  field: Field;
  answer: Answer;
  index?: number;
  handleEdit: () => void;
}

export function FieldBoxComponent({
  field, answer, index, handleEdit,
}: IFieldBox) {
  switch (field.type) {
    case 'signature':
      return <FieldBoxWithImage field={field} answer={answer} index={index} handleClickEdit={handleEdit} />;
    case 'draw':
      return <FieldBoxWithImage field={field} answer={answer} index={index} handleClickEdit={handleEdit} />;
    case 'image':
      return <FieldBoxWithImage field={field} answer={answer} index={index} handleClickEdit={handleEdit} />;
    default:
      return <FieldBox field={field} answer={answer} index={index} handleClickEdit={handleEdit} />;
  }
}
