import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Button } from 'pages/FieldNotebook/components/Button/Button';
import { useSelector } from 'react-redux';
import { ReduxState } from 'store';
import { Archive } from 'store/states/archives/types';
import { Form, FormState } from 'store/states/forms/types';
import { Container, Buttons } from './styles';

interface CancelNoteProps {
  closeModal: () => void;
  type: 'fill' | 'historic';
}

export function CancelNote({ closeModal, type }: CancelNoteProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id: archiveId } = useSelector<ReduxState, Archive>((state) => state.archives.selectedArchive);
  const { form_id: formId } = useSelector<ReduxState, Form>((state) => state.forms.selectedForm);

  const navigateToHomePage = () => {
    const url = type === 'fill'
      ? '/field-notebook/archives'
      : `/field-notebook/archives/${archiveId}/forms/${formId}/answers`;
    navigate(url);
  };

  return (
    <Container>
      <Buttons>
        <Button onClick={closeModal}>{t('back')}</Button>
        <Button
          variant='contained'
          color='error'
          onClick={navigateToHomePage}
        >
          {t('cancel_annotation')}
        </Button>
      </Buttons>
    </Container>
  );
}
