import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';

export const CardGroup = styled(Grid)`
  > div {
    @media(max-width: 425px) {
      width: 335px;
    }

    p {
      @media(max-width: 425px) {
        width: 305px;
      }
    }
  }
`;
