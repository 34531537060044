import { useEffect } from 'react';
import { Component, ProviderProps } from 'providers';
import { getCookie } from 'utils/shared/getCookie';
import { useDispatch } from 'react-redux';
import { setToken, setUserLanguage } from 'store/states/user/actions';
import { getOrganizationRequest, setOrganizationIds } from 'store/states/organization/actionts';
import { useTranslation } from 'react-i18next';
import { getArchivesRequest } from 'store/states/archives/actions';

const v4_url = process.env.REACT_APP_V4_BASE_URL;

export function GetBrowserCookiesProvider({
  children,
}: ProviderProps) {
  const { i18n } = useTranslation();

  const token = getCookie('userToken');
  const externalId = getCookie('externalId');
  const orgId = getCookie('orgId');
  const language = getCookie('language');
  const dispatch = useDispatch();

  useEffect(() => {
    if (token) {
      dispatch(setToken(token));
    } else {
      window.location.href = `${v4_url}/users/sign_in`;
    }
  }, [token, dispatch]);

  useEffect(() => {
    if (externalId && orgId) {
      dispatch(setOrganizationIds(externalId, orgId));
      dispatch(getArchivesRequest());
      dispatch(getOrganizationRequest());
    }
  }, [externalId, orgId, dispatch]);

  useEffect(() => {
    if (language) {
      dispatch(setUserLanguage(language));
      i18n.changeLanguage(language);
    }
  }, [language, dispatch, i18n]);

  return (
    <Component.Provider value={null}>
      {children}
    </Component.Provider>
  );
}
