import { themeOptions } from 'assets/styles/theme';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { ReduxState } from 'store';
import { setSelectedArchive } from 'store/states/archives/actions';
import { Archive, ArchiveState } from 'store/states/archives/types';
import { OrganizationState } from 'store/states/organization/types';
import { Card } from '../components/Card/Card';
import { CardGroup } from '../components/CardGroup/CardGroup';
import { CardsLoading } from '../components/CardsLoading/CardsLoading';
import { Container } from '../components/Container/Container';
import { SubTitle } from '../components/SubTitle/SubTitle';
import { TabsMenu } from '../components/TabsMenu/TabsMenu';
import { Title } from '../components/Title/Title';

export function Historics() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { archives, loading } = useSelector<ReduxState, ArchiveState>((state) => state.archives);
  const { loading: orgLoading } = useSelector<ReduxState, OrganizationState>((state) => state.organization);

  const handleSelectedArchive = (archive: Archive) => {
    dispatch(setSelectedArchive(archive));
    navigate(`/field-notebook/archives/${archive.id}/forms/historic`);
  };
  return (
    <Container>
      <Title>{t('field_notebook')}</Title>

      <TabsMenu />

      <SubTitle>{t('historic_annotation')}</SubTitle>

      <CardGroup gap={2} container columns={3}>
        { loading || orgLoading
          ? <CardsLoading totalItems={4} />
          : (
            <>
              {archives.map((archive) => (
                <Card
                  key={archive.id}
                  title={archive.name.toUpperCase()}
                  subTitle={`${t('historic_of')}: ${archive.description}`}
                  colortitle={themeOptions.palette.primary.main}
                  onClick={() => handleSelectedArchive(archive)}
                />
              ))}
            </>
          )}
      </CardGroup>
    </Container>
  );
}
