import { useTranslation } from 'react-i18next';
import { Tab } from '@mui/material';

import { useLocation, useNavigate } from 'react-router';
import { useState } from 'react';
import { TabsContainer } from './styles';

export function TabsMenu() {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const conditionalTab = pathname.includes('/field-notebook/archives') ? 0 : 1;

  const [tab] = useState(() => conditionalTab);

  const handleChangeTab = () => navigate(
    tab
      ? '/field-notebook/archives'
      : '/field-notebook/historic',
  );

  return (
    <TabsContainer
      value={tab}
    >
      <Tab
        label={t('annotation')}
        key={0}
        onClick={handleChangeTab}
      />
      <Tab
        label={t('historic')}
        key={1}
        onClick={handleChangeTab}
      />
    </TabsContainer>
  );
}
