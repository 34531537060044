import {
  ListItem,
  ListItemText,
  SwipeableDrawer,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import styledComponent from 'styled-components';
import { ChevronLeft } from '@mui/icons-material';
import { themeOptions } from 'assets/styles/theme';

export const ListItemTextStylized = styled(ListItemText)`
  color: ${themeOptions.palette.common.black};
`;

export const ChevronLeftIcon = styled(ChevronLeft)`
  color: ${themeOptions.palette.common.black};
`;

export const SwipeableDrawerStylized = styled(SwipeableDrawer)`
    width: 30%;
    flex-shrink: 0;
    white-space: 'nowrap';     
`;

export const ListItemStylized = styled(ListItem)`
    &:hover {
        text-decoration: underline;
    }    
`;

export const MenuText = styled(Typography)`
    display: none;    
    color: ${themeOptions.palette?.text?.primary};
    font-weight: 500;
    text-decoration: underline;
    padding: 8px 0;
    font-size: 14px;

    @media(max-width: 480px) {
        display: inline;
    }
`;

export const LinkLogo = styledComponent.a`
    text-decoration: 'none';
     display: 'grid';
`;
