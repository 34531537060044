import { createContext, ReactNode } from 'react';
import { GetBrowserCookiesProvider } from './GetBrowserCookiesProvider';
import { PageViewsEventsProvider } from './PageViewsEventsProvider';
import { SetUserPropertiesProvider } from './SetUserPropertiesProvider';
import { SurvicateProvider } from './SurvicateProvider';

export const Component = createContext(null);

export interface ProviderProps {
  children: ReactNode;
}

export function Providers({ children }: ProviderProps) {
  return (
    <Component.Provider value={null}>
      <SurvicateProvider>
        <GetBrowserCookiesProvider>
          <SetUserPropertiesProvider>
            <PageViewsEventsProvider>
              {children}
            </PageViewsEventsProvider>
          </SetUserPropertiesProvider>
        </GetBrowserCookiesProvider>
      </SurvicateProvider>
    </Component.Provider>
  );
}
