import amplitude from 'amplitude-js';

const key = process.env.REACT_APP_AMPLITUDE_KEY || 'a';

export const tracking = amplitude.getInstance('tracking-instance');

export const startTracking = () => tracking.init(
  key,
  undefined,
  {
    saveEvents: true,
    includeUtm: true,
    includeReferrer: true,
    includeGclid: true,
  },
);
