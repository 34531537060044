import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import rootReducer from './states/rootReducer';

import rootSaga from './states/rootSaga';
import { UserState } from './states/user/types';
import { ArchiveState } from './states/archives/types';
import { FormState } from './states/forms/types';
import { SnackbarState } from './states/snackbar/types';
import { HistoricState } from './states/historics/types';
import { OrganizationState } from './states/organization/types';

export interface ReduxState {
    user: UserState;
    archives: ArchiveState;
    forms: FormState;
    snackbar: SnackbarState;
    historics: HistoricState;
    organization: OrganizationState;
}

const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware];

const persistConfig = {
  key: '@agro-e-pop',
  storage,
  version: 1,
  stateReconciler: autoMergeLevel2,
  migrate: (state:any) => Promise.resolve(state),
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(
  persistedReducer,
  composeWithDevTools(
    applyMiddleware(...middlewares),
  ),
);

sagaMiddleware.run(rootSaga);

const persistor = persistStore(store);

export { store, persistor };
