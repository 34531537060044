import {
  Card, Grid, Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { themeOptions } from 'assets/styles/theme';

export const RequiredText = styled(Typography)`
    color: ${themeOptions.palette?.error.main};
    font-size: 12px;
`;

export const ContentContainer = styled('div')`
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    `;

export const CardContainer = styled(Card)`
    width: 100%;
    max-width: 420px;
    height: 350px;
    padding: 0 10px;
    overflow: auto;
`;

export const CardDescriptionContainer = styled('div')`
`;

export const FormContainer = styled('div')`
    width: 100%;
    height: 100%;

    max-height: 207px;
    min-height: 170px;

    padding-top: 10px;


    display: flex;      
    justify-content: center;
    align-items: flex-start;
    overflow-y: hidden;

    ::-webkit-scrollbar {
      width: 5px;
      padding-left: 10px;
    }

    ::-webkit-scrollbar-thumb {
      background-color: #ccc;
    }
`;
export const ButtonContainer = styled(Grid)`
    display: flex;
    gap: 60px;

    button {
      width: 100%;
    }
`;

export const Footer = styled('div')`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 30px 0 10px 0;
`;
