export enum UserActionType {
  SET_TOKEN = '@user/SET_TOKEN',
  SET_USER_PROPERTIES = '@user/SET_USER_PROPERTIES',
  SET_USER_LANGUAGE = '@user/SET_USER_LANGUAGE',
}

export interface UserState {
  jwt: string;
  language: string;
  properties: User;
  success: boolean;
  isLoading: boolean;
  error: boolean;
}

export interface User {
  exp?: number,
  iss?: string,
  aud?: string,
  user_id?: number,
  name?: string,
  email?: string,
  phone?: string,
  created_at?: string,
  external_id?: number,
  last_name?: string;
  organization?: number | null;
  products?: Product[];
}

export interface Product {
  id: number;
  kind: string;
}
