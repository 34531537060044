import { forwardRef } from 'react';
import {
  Snackbar as MUISnackbar, Alert as MuiAlert, Stack,
} from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { ReduxState } from 'store';
import { SnackbarState } from 'store/states/snackbar/types';
import { closeSnackbar } from 'store/states/snackbar/actions';
import { Cancel, CheckCircle } from '@mui/icons-material';

const Alert = forwardRef(
  (props: any, ref: any) => <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />,
);

const getIcon = (typeMessage: string) => {
  switch (typeMessage) {
    case 'success':
      return <CheckCircle />;
    case 'error':
      return <Cancel />;
    default:
      return null;
  }
};

export function Snackbar() {
  const dispatch = useDispatch();
  const { isOpen, message, typeMessage } = useSelector<ReduxState, SnackbarState>((state) => state.snackbar);

  const handleClose = () => dispatch(closeSnackbar());

  const Icon = getIcon(typeMessage);

  return (
    <Stack spacing={1} sx={{ width: '100%' }}>
      <MUISnackbar open={isOpen} autoHideDuration={6000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity={typeMessage}
          sx={{ width: '100%' }}
          icon={Icon}
        >
          {message}
        </Alert>
      </MUISnackbar>
    </Stack>
  );
}
