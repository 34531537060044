import { cultivoInteligenteClient } from 'api/cultivoInteligenteClient';
import { DefaultParams } from 'types/types';

export function* getOrganizationData({ jwt, externalId }: DefaultParams): any {
  const url = 'organizations/details';
  const params = { external_id: externalId };
  const headers = { Authorization: `Bearer ${jwt}` };
  const response = yield cultivoInteligenteClient.get(url, { headers, params });

  return response;
}
