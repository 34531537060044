/* eslint-disable no-param-reassign */
import { Reducer } from 'redux';
import produce from 'immer';
import { Organization, OrganizationState, OrganizationActionType } from './types';

const INITIAL_STATE: OrganizationState = {
  organization: {} as Organization,
  externalId: -1,
  orgId: -1,
  error: false,
  loading: false,
  success: false,
};

const organization: Reducer<OrganizationState> = (state = INITIAL_STATE, action) => produce(state, (draft) => {
  switch (action.type) {
    case OrganizationActionType.GET_ORGANIZATION_REQUEST: {
      draft.loading = true;
      break;
    }
    case OrganizationActionType.GET_ORGANIZATION_SUCCESS: {
      const { organization: OrganizationData } = action.payload;
      draft.organization = OrganizationData;
      draft.success = true;
      draft.loading = false;
      break;
    }

    case OrganizationActionType.GET_ORGANIZATION_FAILURE: {
      draft.loading = false;
      draft.error = true;
      break;
    }
    case OrganizationActionType.SET_ORGANIZATION_IDS: {
      const { externalId, orgId } = action.payload;
      draft.externalId = externalId;
      draft.orgId = orgId;
      break;
    }
    default: {
      return state;
    }
  }
});

export default organization;
