import {
  Divider as DividerMUI,
  Grid,
} from '@mui/material';
import TimelineMUI from '@mui/lab/Timeline';
import TimelineItemMUI from '@mui/lab/TimelineItem';
import TimelineConnectorMUI from '@mui/lab/TimelineConnector';
import { styled } from '@mui/material/styles';
import { themeOptions } from 'assets/styles/theme';

export const Timeline = styled(TimelineMUI)`
  color: #000;
`;

export const TimelineItem = styled(TimelineItemMUI)`
  ::before { content: none }
  &:last-child {
    hr { display: none }
  }
`;

export const TimelineConnector = styled(TimelineConnectorMUI)`
  background: none;
  border: 1px dashed ${themeOptions.palette.primary.light};
`;

export const Divider = styled(DividerMUI)`
  margin: 16px 0;
`;

export const GridFirstContent = styled(Grid)`
   display: 'flex';
   flex-direction: 'row';
   margin-bottom: 20px;
`;
