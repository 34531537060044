import { Archive, ArchivesActionTypes } from './types';

export const getArchivesRequest = () => ({
  type: ArchivesActionTypes.GET_ARCHIVES_REQUEST,
});

export const getArchivesSuccess = (archives: Archive[]) => (
  {
    type: ArchivesActionTypes.GET_ARCHIVES_SUCCESS,
    payload: { archives },
  }
);

export const getArchivesFailure = () => (
  {
    type: ArchivesActionTypes.GET_ARCHIVES_FAILURE,
  }
);

export const setSelectedArchive = (archive: Archive) => (
  {
    type: ArchivesActionTypes.SET_ARCHIVE_SELECTED,
    payload: { archive },
  }
);
