import { Fab } from '@mui/material';
import { themeOptions } from 'assets/styles/theme';
import CanvasDraw from 'react-canvas-draw';
import styled from 'styled-components';

interface ColorPreviewProps {
  color: string;
}

interface AgroFabProps {
  backgroundColor: string;
}

export const DrawContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const UserDrawModalButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
`;

export const UserDraw = styled(CanvasDraw)`
  border: 1px solid ${themeOptions.palette.primary.light};
  position: relative;
  
  @media screen and (min-width: 0px) {
    width: 100% !important;
    height: 350px !important;
  }

  @media screen and (min-width: 450px) {
    width: 100% !important;
    height: 350px !important;
  }

  @media screen and (min-width: 850px) {
    width: 550px !important;
    height: 550px !important;
  }

  @media screen and (min-width: 1100px) {
    width: 800px !important;
    height: 500px !important;
  }
`;

export const UserDrawPreview = styled('img')`
  padding: 8px;
  border: 1px solid ${themeOptions.palette.primary.light};
  width: 100%;
  height: 140px;
`;

export const UserDrawToolbar = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 32px;
`;

export const SelectBrushColor = styled.div`
  display: flex;
  gap: 10px;
  margin-right: 20px;
  animation: slideIn 1s ease 0s 1 normal none;

  @keyframes slideIn {
    0% {
      opacity: 0;
      transform: translateX(250px);
    }

    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }
`;

export const ColorPreview = styled.div<ColorPreviewProps>`
  width: 24px;
  height: 24px;
  background-color: ${(props) => props.color};
  border-radius: 15px;
  border: 1px solid ${themeOptions.palette.grey[300]};
  cursor: pointer;
`;

export const AgroFab = styled(Fab)<AgroFabProps>`
  background-color: ${(props) => props.backgroundColor};
`;
