import { useTranslation } from 'react-i18next';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import { useEffect, useState } from 'react';
import DatePicker from 'components/Forms/DatePicker/DatePicker';
import { filterPerDate } from 'utils/fieldNotebookUtils/historicFilter';
import { useDispatch } from 'react-redux';
import { HistoricsActionType } from 'store/states/historics/types';
import { setFilterOptions } from 'store/states/historics/actions';
import { TrackingService } from 'services/trackingService';
import { Container } from './styles';
import { filterOptions } from './filterOptions';

export function HistoricFilter() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [value, setValue] = useState<string>('all_annotations');
  const [startDate, setStartDate] = useState<string>('');
  const [endDate, setEndDate] = useState<string>('');

  useEffect(() => {
    const { initialDate, finalDate } = filterPerDate({ type: value, initialDate: startDate, finalDate: endDate });
    if ((initialDate && finalDate) || value !== 'custom') {
      dispatch(setFilterOptions({ initialDate, finalDate }));
      dispatch({ type: HistoricsActionType.CLEAR_HISTORIC });
      dispatch({ type: HistoricsActionType.GET_HISTORICS_REQUEST });
    }
  }, [value, startDate, endDate, dispatch]);

  return (
    <FormControl>
      <Container>
        <InputLabel id='period-label'>{t('period')}</InputLabel>
        <Select
          labelId='period-label'
          label={t('period')}
          value={value}
          onChange={(e) => {
            setValue(e.target.value);
            setStartDate('');
            setEndDate('');
            TrackingService.sendEvent('fieldnotebookdatafilter_click');
          }}
        >
          {filterOptions.map((period) => (
            <MenuItem key={period.value} value={period.key}>{t(period.key)}</MenuItem>
          ))}
        </Select>
        {value === 'custom' && (
          <>
            <DatePicker label={t('start_date')} setValue={setStartDate} defaultValue={startDate} />
            <DatePicker label={t('end_date')} setValue={setEndDate} defaultValue={endDate} />
          </>
        )}
      </Container>
    </FormControl>
  );
}
