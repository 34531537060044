import { createTheme } from '@mui/material';

export const themeOptions = createTheme({
  palette: {
    background: {
      paper: '#FFFF',
      default: '#FAFAFA',
    },
    text: {
      primary: '#393939',
      secondary: '#767676',
      disabled: '#E3E3E3',
    },
    primary: {
      main: '#006CC5',
      light: '#008CE4',
      dark: '#00569E',
    },
    secondary: {
      dark: '#3A5D26',
      light: '#66A343',
      main: '#f50057',
    },
    success: {
      main: '#00A420',
      dark: '#088900',
      light: '#2FCA4D',
    },
    warning: {
      main: '#FFA800',
      light: '#FFC148',
      dark: '#FF8A00',
    },
    error: {
      main: '#F03939',
      dark: '#C22424',
      light: '#FF5353',
    },
    common: {
      black: '#393939',
      white: '#FFFFFF',
    },
    grey: {
      100: '#FAFAFA',
      200: '#F6F6F6',
      300: '#E3E3E3',
      400: '#A4A7AF',
      500: '#767676',
      600: '#393939',
    },
  },
});
