export enum SnackbarActions {
  SHOW_SNACKBAR = '@snackbar/SHOW_SNACKBAR',
  CLOSE_SNACKBAR = '@snackbar/CLOSE_SNACKBAR',
}

export interface SnackbarState {
  isOpen: boolean;
  typeMessage: 'success' | 'error' | 'warning' | 'info';
  message: string;
}
