import AreasIcon from 'assets/icons/areas.svg';
import MapsIcon from 'assets/icons/maps.svg';
import EquipmentsIcon from 'assets/icons/equipments.svg';
import InputsIcon from 'assets/icons/inputs.svg';
import CulturesIcon from 'assets/icons/cultures.svg';
import SeasonsIcon from 'assets/icons/seasons.svg';
import StoreIcon from 'assets/icons/store.svg';
import WidgetsIcon from 'assets/icons/widgets.svg';

export interface MenuOption {
    text:string;
    icon:string;
    path:string;
    key:string;
    hide?:boolean;
  }
export const menuOptions: MenuOption[] = [
  {
    text: 'Mapa', icon: MapsIcon, path: 'maps', key: 'map',
  },
  {
    text: 'Widgets', icon: WidgetsIcon, path: 'widgets', key: 'widgets',
  },
  {
    text: 'Areas', icon: AreasIcon, path: 'areas', key: 'areas',
  },
  {
    text: 'Equipamentos', icon: EquipmentsIcon, path: 'equipments', key: 'equipments',
  },
  {
    text: 'Culturas', icon: CulturesIcon, path: 'cultures', key: 'crops',
  },
  {
    text: 'Safras', icon: SeasonsIcon, path: 'seasons', key: 'seasons',
  },
  {
    text: 'Insumos', icon: InputsIcon, path: 'inputs', key: 'inputs',
  },
  {
    text: 'Loja', icon: StoreIcon, path: 'store', key: 'store',
  },
];
