import { MobileStepper } from '@mui/material';
import { styled } from '@mui/material/styles';

export const ProgressBarContainer = styled(MobileStepper)`
  span {
    width: 315px;
    height: 8px;
    border-radius: 4px;
    margin-bottom: 12px;
  }
`;
