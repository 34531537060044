import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { ReduxState } from 'store';
import { FormState } from 'store/states/forms/types';
import { Button } from 'pages/FieldNotebook/components/Button/Button';
import { Title } from 'pages/FieldNotebook/components/Title/Title';
import { SubTitle } from 'pages/FieldNotebook/components/SubTitle/SubTitle';
import { Archive } from 'store/states/archives/types';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { nextStep, previousStep } from 'store/states/forms/actions';
import { useState, useEffect, useRef } from 'react';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { Container } from 'pages/FieldNotebook/components/Container/Container';
import { ImperativeModalHandler, Modal } from 'components/Modal/Modal';
import {
  CardDescriptionContainer,
  ButtonContainer,
  Footer,
  ContentContainer,
  CardContainer,
  FormContainer,
  RequiredText,
} from './styles';
import FormSelector from '../components/FormSelector/FormSelector';
import { StepProgressBar } from '../components/StepProgressBar/StepProgressBar';
import { CancelNote } from '../components/CancelNote/CancelNote';

interface ButtonsProps {
  handlePreviousStep: () => void;
  handleNextStep: () => void;
  isFirstStep: boolean;
  isNextStepValid?: boolean;
  isLastStep: boolean;
  t: (key: string) => string;
}

const Buttons = ({
  handleNextStep,
  handlePreviousStep,
  isNextStepValid,
  isFirstStep,
  isLastStep,
  t,
}: ButtonsProps) => (
  <ButtonContainer>
    <Button
      leftIcon={<KeyboardArrowLeftIcon />}
      onClick={() => handlePreviousStep()}
      disabled={isFirstStep}
    >
      {t('back')}
    </Button>
    <Button
      rightIcon={<KeyboardArrowRightIcon />}
      onClick={() => handleNextStep()}
      disabled={!isNextStepValid}
      variant='contained'
    >
      {isLastStep ? t('resume') : t('next')}
    </Button>
  </ButtonContainer>
);

export default function EditAnswerPage() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { selectedForm, answerEdit, fillData } = useSelector<
    ReduxState,
    FormState
  >((state) => state.forms);
  const { name: title } = useSelector<ReduxState, Archive>(
    (state) => state.archives.selectedArchive,
  );
  const {
    answers, step, totalSteps, notHasLastQuestion,
  } = fillData;

  const [value, setValue] = useState('');

  const modalRef = useRef<ImperativeModalHandler>(null);

  const actualField = selectedForm.fields[step];
  const isFirstStep = step === 0;
  const isLastStep = step === totalSteps - 1;

  const isOptional = actualField.optional;
  const isFilled = value.length > 0;
  const isNextStepValid = isOptional || isFilled;

  const navigateToSummaryPage = () => navigate('/field-notebook/historic/summary');

  useEffect(() => {
    if (notHasLastQuestion) navigate('/field-notebook/forms/summary');
    const answerValue = answers.find(
      (ans) => ans.field_id === actualField?.field_id,
    )?.value;
    if (answerValue) {
      setValue(answerValue);
    } else {
      setValue('');
    }
  }, [actualField?.field_id, answers, navigate, notHasLastQuestion]);

  const handleNextStep = () => {
    dispatch(nextStep({ field_id: actualField.field_id, value }));
    if (isLastStep) {
      navigateToSummaryPage();
    }
    setValue('');
  };

  const handlePreviousStep = () => {
    dispatch(previousStep());
  };

  const handleOpenModal = () => modalRef.current?.open();

  const handleCloseModal = () => modalRef.current?.close();

  return (
    <Container>
      <Title>{title}</Title>
      <SubTitle>{t('field_annotation')}</SubTitle>
      <Button
        style={{ marginBottom: 20 }}
        color='error'
        onClick={() => handleOpenModal()}
      >
        {t('cancel_annotation')}
      </Button>
      <ContentContainer>
        <CardContainer>
          <CardDescriptionContainer>
            <Title>{selectedForm.title}</Title>
            <SubTitle>
              {actualField.description}
              {!isOptional && (
                <RequiredText>
                  *
                  {t('required_field')}
                </RequiredText>
              )}
            </SubTitle>
          </CardDescriptionContainer>
          <FormContainer>
            <FormSelector
              field={actualField}
              value={value}
              setValue={setValue}
            />
          </FormContainer>
        </CardContainer>
        <Footer>
          <StepProgressBar step={step + 1} totalSteps={totalSteps} />
          <Buttons
            handleNextStep={handleNextStep}
            handlePreviousStep={handlePreviousStep}
            isFirstStep={isFirstStep}
            isLastStep={isLastStep}
            isNextStepValid={isNextStepValid}
            t={t}
          />
        </Footer>
      </ContentContainer>

      <Modal
        ref={modalRef}
        title={t('cancel')}
        subtitle={t('cancel_annotation_descripton')}
      >
        <CancelNote closeModal={handleCloseModal} type='historic' />
      </Modal>
    </Container>
  );
}
