import { Archive } from 'store/states/archives/types';
import { Form } from 'store/states/forms/types';

export function formatText(forms:Form[]) {
  let text = '';
  const num = forms.length >= 3 ? 3 : forms.length;
  for (let i = 0; i < num; i += 1) {
    text = `${text + forms[i].title}, `;
  }
  text = `${text.slice(0, -2)}...`;

  return text;
}

export function formatArchives(archives: Archive[]) {
  const newArray = archives.map((archive: Archive) => ({
    ...archive,
    description: formatText(archive.forms),
  }));

  return newArray;
}

export function formatDate(date: Date, locale: string) {
  const formattedDate = new Intl.DateTimeFormat(locale).format(date);

  return formattedDate;
}

export function formatTimestampDate(date: string | number, locale: string) {
  const dateParsed = date.toString();
  const dateToFormat = new Date(parseInt(dateParsed, 10));
  return formatDate(dateToFormat, locale);
}
