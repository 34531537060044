import { tracking } from 'config/amplitude';

export class TrackingService {
  static sendEvent(eventName: string, eventProperties?: object) {
    if (tracking.getUserId !== null) { tracking.logEvent(eventName, eventProperties); }
  }

  static watchGenericEvent(eventName: string) {
    this.sendEvent(eventName);
  }

  static watchPageViews(eventName: string, pagePath: string, pageQuery: string) {
    const formattedPageQuery = pageQuery.replace('?', '');
    const validatedPageQuery = pageQuery ? formattedPageQuery : '""';

    const eventProperties = {
      page_path: pagePath,
      page_query: validatedPageQuery,
    };

    this.sendEvent(eventName, eventProperties);
  }
}
