import styled from 'styled-components';
import { themeOptions } from 'assets/styles/theme';

interface ThumbnailImageProps {
  image: string;
}

export const ThumbnailImage = styled.div<ThumbnailImageProps>`
  width: 180px;
  height: 100px;
  border-radius: 5px;
  cursor: pointer;

  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000d1 100%), url(${(props) => props.image});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  display: flex;
  align-items: flex-end;
  justify-content: center;

  transition: 0.2s filter;

  &:hover {
    filter: brightness(0.85);
  }

  div {
    width: 100%;
    padding: 0 5px 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    color: ${themeOptions.palette.common.white};

    p {
      font-size: 11px;
    }

    img {
      width: 17px;
      height: 17px;
    }
  }
`;
