import {
  Typography,
  ButtonGroup,
  Box,
  Divider as DividerMUI,
} from '@mui/material';
import TimelineMUI from '@mui/lab/Timeline';
import TimelineItemMUI from '@mui/lab/TimelineItem';
import TimelineConnectorMUI from '@mui/lab/TimelineConnector';
import { styled } from '@mui/material/styles';
import { themeOptions } from 'assets/styles/theme';
import { Button } from '../components/Button/Button';

export const ToolsBar = styled(ButtonGroup)`
  max-width: 100%;
  margin-bottom: 20px;
  margin-top: 20px;

  display: flex;
  justify-content: space-between;

  button {
    border-radius: 5px !important;
    border-right-color: rgba(102, 163, 67, 0.5) !important;
    margin-right: 2rem;
  }
`;

export const Timeline = styled(TimelineMUI)`
  color: #000;
`;

export const TimelineItem = styled(TimelineItemMUI)`
  ::before {
    content: none;
  }

  &:last-child {
    hr {
      display: none;
    }
  }
`;

export const TimelineConnector = styled(TimelineConnectorMUI)`
  background: none;
  border: 1px dashed ${themeOptions.palette.primary.light};
`;

export const Date = styled('span')`
  color: ${themeOptions.palette.common.black};
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 16px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  gap: 8px;

  div {
    display: flex;
  }

  svg {
    width: 21px;
    color: ${themeOptions.palette.primary.main};
    cursor: pointer;

    transition: filter 0.5s;

    &:hover {
      filter: brightness(0.8);
    }
  }
`;

export const AnswerField = styled(Box)``;

export const Divider = styled(DividerMUI)`
  margin: 16px 0;
`;

export const DownloadCSVButton = styled(Button)`
  height: 50px;
`;

export const EditedText = styled(Typography)`
  color: ${themeOptions.palette.grey[500]};
  font-size: 14px;

  @media (max-width: 380px) {
    font-size: 12px;
  }
`;

export const DontHaveHistoricsContainer = styled('div')`
  margin-top: 2rem;

  display: flex;
  flex-direction: column;
  align-items: center;

  color: ${themeOptions.palette.grey[300]};

  svg {
    margin-bottom: 1rem;
  }
`;

export const DontHaveArchivesContainer = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-top: 4rem;

  h3 {
    color: ${themeOptions.palette.grey[700]};
    margin-bottom: 1rem;
  }

  p {
    color: ${themeOptions.palette.grey[300]};
    font-size: small;
  }

  & > svg {
    margin-bottom: 1rem;
  }
`;

export const ShowFullImage = styled('img')`
  border: 1px solid ${themeOptions.palette.primary.light};
  border-radius: 8px;
`;

export const DownloadPDFButton = styled(Button)`
  width: 200px;
  span {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
