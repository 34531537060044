import { SnackbarActions, SnackbarState } from './types';

export const showSnackbar = ({ message, typeMessage }: Omit<SnackbarState, 'isOpen'>) => (
  {
    type: SnackbarActions.SHOW_SNACKBAR,
    payload: { message, typeMessage },
  }
);

export const closeSnackbar = () => (
  {
    type: SnackbarActions.CLOSE_SNACKBAR,
  }
);
