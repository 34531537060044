import imageCompression from 'browser-image-compression';

const blobToBase64 = (blob: Blob) : Promise<string | ArrayBuffer | null> => {
  const reader = new FileReader();
  reader.readAsDataURL(blob);
  return new Promise<string | ArrayBuffer | null>((resolve) => {
    reader.onloadend = () => {
      resolve(reader.result);
    };
  });
};

export async function compressImage(file: File, setPercentage?: (progress: number) => void): Promise<File> {
  const options = {
    maxSizeMB: 0.2,
    onProgress: (progress: number) => setPercentage && setPercentage(progress),
    interations: 100,
  };

  return imageCompression(file, options);
}

export async function getCompressImageBase64(file: File, setPercentage?: (progress: number) => void): Promise<string> {
  const compreessFile = await compressImage(file, setPercentage);
  const fileBase64 = await blobToBase64(compreessFile);

  return fileBase64?.toString() || '';
}
