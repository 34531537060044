import { useEffect } from 'react';
import { useLocation } from 'react-router';
import { Component, ProviderProps } from 'providers';
import { TrackingService } from 'services/trackingService';

export function PageViewsEventsProvider({
  children,
}: ProviderProps) {
  const { pathname, search } = useLocation();

  useEffect(() => {
    TrackingService.watchPageViews('Page View', pathname, search); // change event name
  }, [pathname, search]);

  return (
    <Component.Provider value={null}>
      {children}
    </Component.Provider>
  );
}
