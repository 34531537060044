import React from 'react';
import TextField from 'components/Forms/TextField/TextField';

interface IDecimal {
    setValue: (value: string) => void;
    defaultValue: string;
}

export default function Decimal({ defaultValue, setValue }: IDecimal) {
  return <TextField setValue={setValue} defaultValue={defaultValue} pattern={/^\d+[,]?\d{0,}$/} />;
}
