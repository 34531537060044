import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { LocalizationProvider, MobileDatePicker } from '@mui/lab';
import { useEffect, useState } from 'react';
import { Stack } from '@mui/material';
import { AnyAction } from 'redux';

interface DatePickerProps {
    label?: string;
    setValue: (value: string) => void;
    defaultValue: string;
}

export default function DatePicker({ defaultValue, label = '', setValue }:DatePickerProps) {
  const [datePickValue, setDatePickValue] = useState<Date | null>(null);

  useEffect(() => {
    if (defaultValue) {
      setDatePickValue(new Date(parseInt(defaultValue, 10)));
    }
  }, [defaultValue]);

  const handleChange = (newValue: Date | null) => {
    newValue?.setHours(0, 0, 0, 0);
    setDatePickValue(newValue);
    setValue(newValue?.getTime().toString() || '');
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Stack sx={{ width: '100%', minWidth: '100px', maxWidth: '150px' }}>
        <MobileDatePicker
          label={label}
          inputFormat='dd/MM/yyyy'
          value={datePickValue}
          onChange={handleChange}
          renderInput={(params: any) => <TextField {...params} />}
          disableFuture
        />
      </Stack>
    </LocalizationProvider>
  );
}
