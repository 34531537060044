import { themeOptions } from 'assets/styles/theme';
import styled from 'styled-components';

export const Text = styled.p`
    color: ${themeOptions.palette.primary.main};
    cursor: pointer;
`;

export const Container = styled.div`

`;
