import { combineReducers } from 'redux';
import user from './user/reducer';
import archives from './archives/reducer';
import forms from './forms/reducer';
import snackbar from './snackbar/reducer';
import historics from './historics/reducer';
import organization from './organization/reducer';

export default combineReducers(
  {
    user,
    archives,
    forms,
    snackbar,
    historics,
    organization,
  },
);
