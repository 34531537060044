import { useDispatch, useSelector } from 'react-redux';
import HistoryIcon from '@mui/icons-material/History';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import { Archive } from 'store/states/archives/types';
import { ReduxState } from 'store';
import { setSelectedForm } from 'store/states/forms/actions';
import { Card } from 'pages/FieldNotebook/components/Card/Card';
import { Button } from 'pages/FieldNotebook/components/Button/Button';
import { Title } from 'pages/FieldNotebook/components/Title/Title';
import { SubTitle } from 'pages/FieldNotebook/components/SubTitle/SubTitle';
import { Form } from 'store/states/forms/types';
import { themeOptions } from 'assets/styles/theme';
import { getHistoricsRequest } from 'store/states/historics/actions';
import { Organization } from 'store/states/organization/types';
import { CardGroup } from '../components/CardGroup/CardGroup';
import { Container } from '../components/Container/Container';
import { ArchiveName } from '../components/ArchiveName/ArchiveName';

export function FormsPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { is_historic } = useParams();

  const archive = useSelector<ReduxState, Archive>((state) => state.archives.selectedArchive);
  const organization = useSelector<ReduxState, Organization>((state) => state.organization.organization);
  const { name: title, forms } = archive;

  const navigateToHomePage = () => navigate(
    is_historic ? '/field-notebook/historic' : '/field-notebook/archives',
  );

  const handleFormSelect = (selectedForm: Form) => {
    dispatch(setSelectedForm(selectedForm, organization));
    if (is_historic) dispatch(getHistoricsRequest());
    navigate(is_historic
      ? `/field-notebook/archives/${archive.id}/forms/${selectedForm.form_id}/answers`
      : `/field-notebook/archives/${archive.id}/forms/${selectedForm.form_id}/fill`);
  };

  return (
    <Container>
      <Title>{t('field_notebook')}</Title>
      <SubTitle>{t('operation_to_register')}</SubTitle>

      <Button
        style={{ marginBottom: 20 }}
        leftIcon={<ArrowBackIcon />}
        onClick={navigateToHomePage}
      >
        {t('back')}
      </Button>

      <ArchiveName>{title}</ArchiveName>

      <CardGroup gap={2} container columns={3}>
        {forms.map((form) => (
          <Card
            key={form.form_id}
            title={form.title.toUpperCase()}
            subTitle={form.description}
            colortitle={themeOptions.palette.primary.main}
            icon={is_historic && <HistoryIcon fontSize='small' />}
            onClick={() => handleFormSelect(form)}
          />
        ))}
      </CardGroup>
    </Container>
  );
}
