import { styled } from '@mui/material/styles';
import { themeOptions } from 'assets/styles/theme';
import { Container as ContainerMUI } from '@mui/material';
import { HistoricFilter as Filter } from './HistoricFilter';

export const HistoricFilter = styled(Filter)`
  width: 100%;
  margin: 30px;
  color: ${themeOptions.palette.common.black};
`;

export const Container = styled(ContainerMUI)`
  width: 100%;
  padding-left: 0px;

  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 30px;
`;
