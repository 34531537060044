import { all } from 'redux-saga/effects';
import { archiveSagas } from './archives/sagas';
import { formSagas } from './forms/sagas';
import { historicSagas } from './historics/saga';
import { organizationSagas } from './organization/saga';

export default function* rootSaga() {
  yield all(
    [
      ...archiveSagas,
      ...formSagas,
      ...historicSagas,
      ...organizationSagas,
    ],
  );
}
