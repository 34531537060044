import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import DownloadIcon from '@mui/icons-material/Download';
import { Button } from 'pages/FieldNotebook/components/Button/Button';
import { downloadImageFromUrl } from 'utils/fieldNotebookUtils/download-image';
import { Container, ButtonContainer } from './styles';

interface ThumbnailFullImageProps {
  imageUrl: string;
}

export function ThumbnailFullImage({
  imageUrl,
}: ThumbnailFullImageProps) {
  const { t } = useTranslation();

  const handleDownloadImage = () => {
    downloadImageFromUrl(imageUrl);
  };

  return (
    <Container>
      <img src={imageUrl} loading='lazy' alt={t('image_of_your_note')} />
      <ButtonContainer>
        <Button
          variant='contained'
          rightIcon={<DownloadIcon />}
          onClick={() => handleDownloadImage()}
        >
          {t('download_image')}
        </Button>
      </ButtonContainer>
    </Container>
  );
}
