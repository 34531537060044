/* eslint-disable no-param-reassign */
import { Reducer } from 'redux';
import produce from 'immer';
import { Archive, ArchivesActionTypes, ArchiveState } from './types';

const INITIAL_STATE: ArchiveState = {
  archives: [],
  selectedArchive: {} as unknown as Archive,
  success: false,
  loading: false,
  error: false,
};

const archives: Reducer<ArchiveState> = (state = INITIAL_STATE, action) => produce(state, (draft) => {
  switch (action.type) {
    case ArchivesActionTypes.GET_ARCHIVES_SUCCESS: {
      const { archives: archivesData } = action.payload;
      draft.archives = archivesData;
      draft.success = true;
      draft.loading = false;
      break;
    }
    case ArchivesActionTypes.GET_ARCHIVES_REQUEST: {
      draft.loading = true;
      break;
    }
    case ArchivesActionTypes.GET_ARCHIVES_FAILURE: {
      draft.success = false;
      draft.loading = false;
      draft.error = true;
      break;
    }
    case ArchivesActionTypes.SET_ARCHIVE_SELECTED: {
      const { archive } = action.payload;
      draft.selectedArchive = archive;
      break;
    }
    default:
      return state;
  }
});

export default archives;
