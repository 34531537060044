import { useTranslation } from 'react-i18next';
import { Button } from 'pages/FieldNotebook/components/Button/Button';
import { Typography } from '@mui/material';
import { ButtonContainer, Buttons } from './styles';

interface NotSuportGeolocationNoteProps {
  closeModal: () => void;
}

export function NotSuportGeolocationNote({
  closeModal,
}: NotSuportGeolocationNoteProps) {
  const { t } = useTranslation();

  return (
    <>
      <Typography variant='body2' color='#000'>{t('unable_geolocation_description')}</Typography>
      <ButtonContainer>
        <Buttons>
          <Button variant='contained' onClick={closeModal}>
            {t('ok')}
            !
          </Button>
        </Buttons>
      </ButtonContainer>
    </>
  );
}
