import { useTranslation } from 'react-i18next';
import { formatTimestampDate } from 'utils/fieldNotebookUtils/formatters';

interface DateInputProps {
  date: string;
}

export function InputDate({ date }: DateInputProps) {
  const { i18n } = useTranslation();
  const locale = i18n.language;

  const formattedDate = formatTimestampDate(date, locale);

  return <>{ formattedDate }</>;
}
