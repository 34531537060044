import SideBar from 'components/SideBar/SideBar';
import { Snackbar } from 'components/Snackbar/Snackbar';
import React from 'react';
import { Container } from './styles';

interface LayoutProps {
    children?: React.ReactNode;
}

export default function Layout({ children }: LayoutProps) {
  return (
    <Container>
      <SideBar />
      <Snackbar />
      {children}
    </Container>
  );
}
