import { AxiosResponse } from 'axios';
import {
  takeEvery, put, fork, select,
} from 'redux-saga/effects';
import { getOrganizationData } from 'services/cultivoInteligenteService';
import {
  getOrganizationSuccess, getOrganizationFailure,
} from './actionts';
import { OrganizationActionType, Organization } from './types';

function* getOrganization() {
  try {
    const { jwt } = yield select((state) => state.user);
    const { externalId } = yield select((state) => state.organization);

    const response: AxiosResponse<Organization> = yield getOrganizationData(
      {
        jwt,
        externalId,
      },
    );
    yield put(getOrganizationSuccess(response.data));
  } catch (error) {
    yield put(getOrganizationFailure());
  }
}

function* watchGetOrganizationRequest() {
  yield takeEvery(OrganizationActionType.GET_ORGANIZATION_REQUEST, getOrganization);
}

export const organizationSagas = [fork(watchGetOrganizationRequest)];
