import { V4ClassEnum } from 'types/v4ClassEnum';
import { HistoricAnswer } from '../historics/types';
import { Organization } from '../organization/types';
import {
  Answer, AnswerUpdateRequestData, Field, Form, UpdateAnswerData,
} from './types';

export function generateUpdateAnswersData(answers: Answer[], historicAnswers: HistoricAnswer[]):AnswerUpdateRequestData {
  const answersData: UpdateAnswerData[] = [];

  answers.forEach((answer, index) => {
    const newAnswer = {
      answer_id: historicAnswers[index].answer_id,
      value: answer.value,
    };
    answersData.push(newAnswer);
  });

  return {
    answers: answersData,
  };
}

export function formatForm(form: Form, organization: Organization): Form {
  const newFields = form.fields.map((field) => ({
    ...field,
    items: field.v4_class ? organization[V4ClassEnum[field.v4_class]] : field.items,
  }));

  return {
    ...form,
    fields: newFields,
  };
}

export function verifyNeedsCleanConditionalAnswersWhenEditing(answer: Answer, answersList: Answer[], fields: Field[]): boolean {
  const existingAnswer = answersList.find((ans: { field_id: any; }) => ans.field_id === answer.field_id);

  if (existingAnswer) {
    const answerField = fields.find((field) => field.field_id === answer.field_id);
    const isConditionalResponse = answerField?.component_depends?.length !== 0;

    if (!isConditionalResponse || existingAnswer.value === null) { return false; }

    return existingAnswer.value !== answer.value;
  }

  return false;
}

export function clearAnswersToNull(answers: Answer[], startIndex: number): Answer[] {
  const clearAnswers = answers.map((answer, index) => {
    if (index >= startIndex) {
      return {
        field_id: answer.field_id,
        value: null,
      };
    }
    return answer;
  });

  return clearAnswers;
}
