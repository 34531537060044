import { themeOptions } from 'assets/styles/theme';
import CanvasDraw from 'react-canvas-draw';
import styled from 'styled-components';

export const SignatureContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const SignatureDraw = styled(CanvasDraw)`
  border: 1px solid ${themeOptions.palette.primary.light};
  position: relative;
  
  @media screen and (min-width: 0px) {
    width: 100% !important;
    height: 350px !important;
  }

  @media screen and (min-width: 450px) {
    width: 100% !important;
    height: 350px !important;
  }

  @media screen and (min-width: 850px) {
    width: 550px !important;
    height: 550px !important;
  }

  @media screen and (min-width: 1100px) {
    width: 800px !important;
    height: 500px !important;
  }
`;

export const SignatureDrawToolbar = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 32px;
`;

export const SignatureModalButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
`;

interface SignaturePreviewProps {
  signature: string;
}
export const SignaturePreview = styled.div<SignaturePreviewProps>`
  width: 100%;
  height: 140px;
  padding: 4px;
  border: 1px solid ${themeOptions.palette.primary.light};

  background-image: url(${(props) => props.signature});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin-bottom: 13px;

  display: flex;
  align-items: flex-start;
  justify-content: flex-end;

  img {
    cursor: pointer;
    transition: 0.2s filter;

    &:hover {
      filter: brightness(0.9);
    }
  }

  button {
    display: none;
  }
`;
