import { themeOptions } from 'assets/styles/theme';
import { ArrowForwardIos } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { getArchivesRequest, setSelectedArchive } from 'store/states/archives/actions';
import { Archive, ArchiveState } from 'store/states/archives/types';
import { OrganizationState } from 'store/states/organization/types';
import { ReduxState } from 'store';
import { ReactComponent as DontHaveArchivesImage } from 'assets/images/FieldNotebook/dont_have_items_farmer.svg';
import ReplayIcon from '@mui/icons-material/Replay';
import { getOrganizationRequest } from 'store/states/organization/actionts';
import { Typography } from '@mui/material';
import { Card } from '../components/Card/Card';
import { CardGroup } from '../components/CardGroup/CardGroup';
import { Container } from '../components/Container/Container';
import { SubTitle } from '../components/SubTitle/SubTitle';
import { TabsMenu } from '../components/TabsMenu/TabsMenu';
import { Title } from '../components/Title/Title';
import { CardsLoading } from '../components/CardsLoading/CardsLoading';
import { Button } from '../components/Button/Button';
import { DontHaveArchivesContainer } from './styles';

export function Archives() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { archives, loading } = useSelector<ReduxState, ArchiveState>((state) => state.archives);
  const { loading: orgLoading } = useSelector<ReduxState, OrganizationState>((state) => state.organization);
  const isLoading = loading || orgLoading;
  const dontHaveArchives = archives.length === 0;

  const handleSelectedArchive = (archive: Archive) => {
    dispatch(setSelectedArchive(archive));
    navigate(`/field-notebook/archives/${archive.id}/forms`);
  };

  const handleLoadingArchives = () => {
    dispatch(getArchivesRequest());
    dispatch(getOrganizationRequest());
  };

  return (
    <Container>
      <Title>{t('field_notebook')}</Title>

      <TabsMenu />

      <SubTitle>{t('historic_annotation')}</SubTitle>

      <CardGroup gap={2} container columns={3}>
        { dontHaveArchives && !isLoading && (
          <DontHaveArchivesContainer>
            <Typography style={{ color: themeOptions.palette.grey[600] }} variant='h6'>
              {t('dont_have_archives_title')}
            </Typography>
            <Typography style={{ color: themeOptions.palette.grey[500] }} variant='body2'>
              {t('dont_have_archives_subtitle')}
            </Typography>
            <DontHaveArchivesImage />
            <Button variant='contained' rightIcon={<ReplayIcon />} onClick={handleLoadingArchives}>
              {t('dont_have_archives_button')}
            </Button>
          </DontHaveArchivesContainer>
        ) }
        {isLoading ? <CardsLoading totalItems={4} />
          : (
            <>
              {archives.map((archive) => (
                <Card
                  key={archive.id}
                  title={archive.name}
                  subTitle={`${t('take_note_of')}: ${archive.description}`}
                  colortitle={themeOptions.palette.text.primary}
                  icon={<ArrowForwardIos fontSize='small' />}
                  onClick={() => handleSelectedArchive(archive)}
                />
              ))}
            </>
          )}
      </CardGroup>
    </Container>
  );
}
