import { Typography, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import { themeOptions } from 'assets/styles/theme';

export const CardContainer = styled(Grid)`
  background: ${themeOptions.palette.common.white};
  height: 85px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  padding: 16px;
  cursor: pointer;
  transition: transform 0.2s;

  display: flex;

  div {
    &:last-child {
      height: 100%;
      
      display: flex;
      align-items: center;
      justify-content: center;
      
      color: ${themeOptions.palette.common.black};
    }
  }

  &:hover { transform: scale(1.05) }
`;

interface CardTitleProps {
  colortitle: string;
}

export const CardTitle = styled(Typography)<CardTitleProps>`
  color: ${(props) => props.colortitle};
  font-size: 16px;
  font-weight: ${themeOptions.typography.fontWeightMedium};
`;

export const CardContents = styled(Typography)`
  width: 336px;

  @media(max-width: 425px) {
    width: 280px;

    p {
      max-width: 100%;
    }
  }

  p {
    color: ${themeOptions.palette?.text?.secondary};
    font-size: 12px;
    font-weight: ${themeOptions.typography.fontWeightRegular};
    line-height: 16px;
    
    overflow: hidden;
    display: -webkit-box;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
`;
