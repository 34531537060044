import { themeOptions } from 'assets/styles/theme';
import styled from 'styled-components';

export const Container = styled.div`

  img {
    width: 100%;
    max-height: 420px;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;
