import { tracking } from 'config/amplitude';
import { ProviderProps, Component } from 'providers';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from 'store';
import { setUserProperties } from 'store/states/user/actions';
import { User, UserState } from 'store/states/user/types';
import { parseJwt } from 'utils/shared/general';

export function SetUserPropertiesProvider({ children }: ProviderProps) {
  const dispatch = useDispatch();
  const {
    properties,
    success,
    jwt,
    isLoading,
  } = useSelector<ReduxState, UserState>((state) => state.user);

  useEffect(() => {
    if (jwt) {
      const user: User = parseJwt(jwt);
      dispatch(setUserProperties(user));
    }
  }, [dispatch, jwt]);

  useEffect(() => {
    if (success) {
      tracking.setUserId(properties?.email || '');
    }
  }, [success, properties]);

  return (
    <Component.Provider value={null}>
      {!isLoading && children}
    </Component.Provider>
  );
}
