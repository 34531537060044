import DefaultLogo from 'assets/icons/Logo/FullLogo.png';
import Icon from 'assets/icons/Logo.png';
import { LogoWrapper } from './styles';

interface LogoProps {
  width?: 'xs' | 'sm' | 'md' | 'lg' ;
  logo?: string;
  smallLogo?: string;
}

export default function Logo({ width = 'md', logo = DefaultLogo, smallLogo = DefaultLogo }: LogoProps) {
  const components = {
    xs: smallLogo || Icon,
    sm: smallLogo || Icon,
    md: logo || DefaultLogo,
    lg: logo || DefaultLogo,
  };

  const Component = DefaultLogo || components[width] || logo;

  return (
    <LogoWrapper>
      <img src={Component} alt='logo' />
    </LogoWrapper>
  );
}
