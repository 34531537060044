import { Container } from '@mui/material';
import { styled } from '@mui/material/styles';
import { themeOptions } from 'assets/styles/theme';

export const Wrapper = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;

  span {
    color: ${themeOptions.palette.grey[300]};
    font-size: 14px;
  }
`;
