import GlobalStyle from 'assets/styles/globalStyle';
import RoutesIndex from 'routes/Routes';
import { BrowserRouter as Router } from 'react-router-dom';
import Layout from 'components/Layout/Layout';
import { Providers } from 'providers';

function App() {
  return (
    <Router>
      <Providers>
        <Layout>
          <RoutesIndex />
          <GlobalStyle />
        </Layout>
      </Providers>
    </Router>
  );
}

export default App;
