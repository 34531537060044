import { TimelineContent, TimelineDot, TimelineSeparator } from '@mui/lab';
import { Grid, Skeleton } from '@mui/material';
import { GridFirstContent, TimelineConnector, TimelineItem } from './styles';

export const TimelineItemSkeleton = () => (
  <TimelineItem>
    <TimelineSeparator>
      <TimelineDot color='success' />
      <TimelineConnector />
    </TimelineSeparator>
    <TimelineContent>
      <GridFirstContent item spacing={3}>
        <Skeleton animation='wave' width={70} height={25} />
        <Skeleton style={{ marginLeft: '8px' }} animation='wave' width={20} height={25} />
      </GridFirstContent>
      <Grid container spacing={3} mb={1}>
        <Grid item xs={6} md={3} sm={4}>
          <Skeleton animation='wave' width={89} height={17} />
          <Skeleton animation='wave' width={56} height={21} />
        </Grid>
        <Grid item xs={6} md={3} sm={4}>
          <Skeleton animation='wave' width={89} height={17} />
          <Skeleton animation='wave' width={56} height={21} />
        </Grid>
        <Grid item xs={6} md={3} sm={4}>
          <Skeleton animation='wave' width={89} height={17} />
          <Skeleton animation='wave' width={56} height={21} />
        </Grid>
        <Grid item xs={6} md={3} sm={4}>
          <Skeleton animation='wave' width={89} height={17} />
          <Skeleton animation='wave' width={136} height={21} />
        </Grid>
      </Grid>
    </TimelineContent>
  </TimelineItem>
);
