import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DownloadIcon from '@mui/icons-material/Download';
import EditIcon from '@mui/icons-material/Edit';
import RefreshIcon from '@mui/icons-material/Refresh';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import { Typography } from '@mui/material';
import { ReactComponent as DownloadPDFIcon } from 'assets/icons/downloadpdf.svg';
import { ReactComponent as DontHaveHistoricImage } from 'assets/images/FieldNotebook/dont_have_historics.svg';
import { ReactComponent as DontHaveArchivesImage } from 'assets/images/FieldNotebook/network_error.svg';
import { themeOptions } from 'assets/styles/theme';
import { TimelineItemSkeleton } from 'components/SekeletonLoading/TimelineItemSkeleton/TimelineItemSkeleton';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { TrackingService } from 'services/trackingService';
import { ReduxState } from 'store';
import { getArchivesRequest } from 'store/states/archives/actions';
import { Archive } from 'store/states/archives/types';
import { setAnswers } from 'store/states/forms/actions';
import { FormState } from 'store/states/forms/types';
import {
  clearHistoric,
  getAllHistoricsRequest,
  getHistoricsRequest,
  setSelectedHistoric,
} from 'store/states/historics/actions';
import { Historic, HistoricState } from 'store/states/historics/types';
import { getOrganizationRequest } from 'store/states/organization/actionts';
import { handleDataToGenerateCsv } from 'utils/fieldNotebookUtils/csvUtils';
import { formatTimestampDate } from 'utils/fieldNotebookUtils/formatters';
import { generateAnswerPdf } from 'utils/fieldNotebookUtils/pdfUtils';
import { Button } from '../components/Button/Button';
import { Container } from '../components/Container/Container';
import { HistoricFilter } from '../components/HistoricFilter/HistoricFilter';
import { SearchBar } from '../components/SearchBar/SearchBar';
import { SubTitle } from '../components/SubTitle/SubTitle';
import { Title } from '../components/Title/Title';
import { HistoricContent } from './HistoricContent';
import {
  Date,
  Divider,
  DontHaveArchivesContainer,
  DontHaveHistoricsContainer,
  DownloadCSVButton,
  DownloadPDFButton,
  EditedText,
  Timeline,
  TimelineConnector,
  TimelineItem,
  ToolsBar,
} from './styles';

export function AnswersPage() {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loaderRef = useRef(null);
  const [downloadCsv, setDownloadCsv] = useState(false);

  const handleLoadingArchives = () => {
    dispatch(getArchivesRequest());
    dispatch(getOrganizationRequest());
  };

  const currentLanguage = i18n.language;
  const { id: archiveId } = useSelector<ReduxState, Archive>(
    (state) => state.archives.selectedArchive,
  );
  const {
    historics, loading, hasNextPage, error,
  } = useSelector<
    ReduxState,
    HistoricState
  >((state) => state.historics);
  const { selectedForm } = useSelector<ReduxState, FormState>(
    (state) => state.forms,
  );
  const hasHistorics = historics.length > 0;

  useEffect(() => {
    dispatch(clearHistoric());
    dispatch(getHistoricsRequest());
  }, []);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '20px',
      threshold: 1.0,
    };

    const observer = new IntersectionObserver((entities) => {
      const target = entities[0];
      if (target.isIntersecting && !error) {
        dispatch(getHistoricsRequest());
      }
    }, options);

    if (loaderRef.current) {
      observer.observe(loaderRef.current);
    }
  }, [dispatch, error, hasNextPage, loading, selectedForm.form_id]);

  useEffect(() => {
    if (!loading && downloadCsv) {
      handleDataToGenerateCsv(historics, selectedForm, currentLanguage);
      setDownloadCsv(false);
    }
  }, [currentLanguage, downloadCsv, historics, loading, selectedForm]);

  const handleEditResponse = (historic: Historic) => {
    dispatch(setSelectedHistoric(historic));
    dispatch(setAnswers(historic.answers));
    navigate('/field-notebook/historic/summary');
  };

  function generateCsv() {
    TrackingService.watchGenericEvent('historyscreen_click_CSVdownloadbutton');
    // solução temporária para o problema de não gerar o csv corretamente
    dispatch(getAllHistoricsRequest());
    setDownloadCsv(true);
  }

  return (
    <Container>
      <Title>{t('field_notebook')}</Title>
      <SubTitle>{t('historic_annotation')}</SubTitle>
      <Button
        leftIcon={<ArrowBackIcon />}
        onClick={() => navigate(`/field-notebook/archives/${archiveId}/forms/historic`)}
      >
        {t('back')}
      </Button>
      <ToolsBar>
        <HistoricFilter />
        <DownloadCSVButton
          variant='contained'
          style={{ height: '50px' }}
          rightIcon={<DownloadIcon />}
          onClick={() => generateCsv()}
          disabled={!hasHistorics}
          isLoading={loading}
        >
          {t('download_csv')}
        </DownloadCSVButton>
      </ToolsBar>
      <SearchBar />
      {(!hasHistorics && !loading) || error ? (
        <>
          {error ? (
            <DontHaveArchivesContainer>
              <Typography style={{ color: themeOptions.palette.primary.main }} variant='h6'>
                {t('an_error_has_occured')}
              </Typography>
              <Typography style={{ color: themeOptions.palette.grey[500] }}>
                {t('unable_to_display_data')}
              </Typography>
              <Typography style={{ color: themeOptions.palette.grey[500], marginBottom: 20 }}>
                {t('try_reload_page')}
              </Typography>
              <DontHaveArchivesImage />
              <Button variant='contained' rightIcon={<RefreshIcon />} onClick={handleLoadingArchives}>
                {t('dont_have_archives_button')}
              </Button>
            </DontHaveArchivesContainer>
          ) : (
            <DontHaveHistoricsContainer>
              <DontHaveHistoricImage />
              (
              <Typography style={{ color: themeOptions.palette.grey[500] }}>
                {t('dont_have_historics')}
              </Typography>
              )
            </DontHaveHistoricsContainer>
          )}
        </>
      ) : (
        <Timeline>
          {hasHistorics
            && historics.map((historic, index) => (
              <TimelineItem key={index}>
                <TimelineSeparator>
                  <TimelineDot color='primary' />
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                  <Date>
                    <div>
                      {formatTimestampDate(historic.form_date, currentLanguage)}
                      <EditIcon onClick={() => handleEditResponse(historic)} />
                      {historic.updated_by && historic.updated_at && (
                        <EditedText>
                          (
                          {t('historic_edited_by')}
                          :
                          {' '}
                          {historic.updated_by}
                          {' '}
                          {formatTimestampDate(
                            historic?.updated_at,
                            currentLanguage,
                          )}
                          )
                        </EditedText>
                      )}
                    </div>
                    <DownloadPDFButton
                      onClick={() => generateAnswerPdf(historic, selectedForm, currentLanguage)}
                      type='button'
                      variant='text'
                    >
                      {t('download_pdf')}
                      {'  '}
                      <DownloadPDFIcon color='primary' />
                    </DownloadPDFButton>
                  </Date>
                  <HistoricContent historic={historic} />
                  <Divider />
                </TimelineContent>
              </TimelineItem>
            ))}
          {loading && !error && (
            <>
              <TimelineItemSkeleton />
              <TimelineItemSkeleton />
            </>
          )}
          {hasNextPage && !loading && <span ref={loaderRef} />}
        </Timeline>
      )}
    </Container>
  );
}
