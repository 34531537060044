import { styled } from '@mui/material/styles';
import { themeOptions } from 'assets/styles/theme';

export const DontHaveArchivesContainer = styled('div')`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    margin-top: 4rem;

    h3 {
        color: ${themeOptions.palette.grey[700]};
        margin-bottom: 1rem;
    }
    
    p {
        color: ${themeOptions.palette.grey[300]};
        font-size: small;
    }

    & > svg {
        margin-bottom: 1rem;
    }
`;
