import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import EditIcon from '@mui/icons-material/Edit';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { ReduxState } from 'store';
import { Answer, Field, FormState } from 'store/states/forms/types';
import {
  editAnswer,
  sendFormRequest,
} from 'store/states/forms/actions';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { ImperativeModalHandler, Modal } from 'components/Modal/Modal';
import { verifyRules } from 'utils/fieldNotebookUtils/verifyRules';
import { ArchiveState } from 'store/states/archives/types';
import { Button } from '../components/Button/Button';
import { Container } from '../components/Container/Container';
import {
  Title,
  SubTitle,
  SummaryCard,
  TitleCard,
  ButtonGroup,
  NoteGroup,
} from './styles';
import { CancelNote } from '../components/CancelNote/CancelNote';
import { FieldBoxComponent } from '../components/FieldBoxComponent/FieldBoxComponent';
import { RulesModal } from '../components/RulesModal/RulesModal';

export function FormsNoteSummary() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const rulesModalRef = useRef<ImperativeModalHandler>(null);

  const title = useSelector<ReduxState, string>(
    (state) => state.archives.selectedArchive.name,
  );
  const answers = useSelector<ReduxState, Answer[]>(
    (state) => state.forms.fillData.answers,
  );
  const { selectedForm, fillData } = useSelector<ReduxState, FormState>(
    (state) => state.forms,
  );
  const { selectedArchive } = useSelector<ReduxState, ArchiveState>(
    (state) => state.archives,
  );
  const { answerLoading, answerError, answerSuccess } = fillData;

  const saveFormResponse = () => {
    dispatch(
      sendFormRequest({
        successMessage: t('annotation_message_success'),
        errorMessage: t('connection_failed_message'),
      }),
    );
  };

  useEffect(() => {
    if (answerSuccess && !answerLoading) {
      navigate(`/field-notebook/forms/${selectedForm.form_id}`);
    }
  }, [
    answerSuccess,
    answerError,
    answerLoading,
    navigate,
    selectedForm.form_id,
    dispatch,
    t,
  ]);

  const handleOpenRulesModal = () => rulesModalRef.current?.open();

  const handleClickEdit = (field: Field) => {
    dispatch(editAnswer(field));
    if (field.component_depends?.length === 0) {
      navigate(`/field-notebook/archives/${selectedArchive.id}/forms/${selectedForm.form_id}/fill`);
    } else {
      handleOpenRulesModal();
    }
  };
  const handleClickRulesModal = () => {
    navigate(`/field-notebook/archives/${selectedArchive.id}/forms/${selectedForm.form_id}/fill`);
  };

  const modalRef = useRef<ImperativeModalHandler>(null);

  const handleOpenCancelNoteModal = () => modalRef.current?.open();
  const handleCloseCancelNoteModal = () => modalRef.current?.close();

  return (
    <Container>
      <Title>{title}</Title>
      <SubTitle>
        {t('summary_title')}
        <span>
          {t('summary_edit')}
          (
          <EditIcon />
          )
        </span>
        {t('summary_edit_text')}
      </SubTitle>

      <SummaryCard>
        <TitleCard>{t('summary_resume')}</TitleCard>

        <NoteGroup>
          {selectedForm.fields.map((field, index) => {
            const answer = answers.find((a) => a.field_id === field.field_id) as Answer;
            const isFieldValid = verifyRules(field, answers);
            return isFieldValid && <FieldBoxComponent field={field} index={index} answer={answer} handleEdit={() => handleClickEdit(field)} />;
          })}
        </NoteGroup>
      </SummaryCard>

      <ButtonGroup>
        <Button onClick={handleOpenCancelNoteModal}>{t('cancel')}</Button>
        <Button
          variant='contained'
          rightIcon={<CheckCircleIcon />}
          isLoading={answerLoading}
          onClick={saveFormResponse}
        >
          {t('save_annotation')}
        </Button>
      </ButtonGroup>

      <RulesModal modalRef={rulesModalRef} handleEdit={handleClickRulesModal} />
      <Modal
        ref={modalRef}
        title={t('cancel')}
        subtitle={t('cancel_annotation_descripton')}
      >
        <CancelNote closeModal={handleCloseCancelNoteModal} type='fill' />
      </Modal>
    </Container>
  );
}
