export const filterOptions = [
  {
    value: '7 days', key: 'last_seven_days',
  },
  {
    value: '15 days', key: 'last_fifteen_days',
  },
  {
    value: '30 days', key: 'last_thirty_days',
  },
  {
    value: 'all', key: 'all_annotations',
  },
  {
    value: 'custom', key: 'custom',
  },
];
