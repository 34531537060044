import { Routes, Route, Navigate } from 'react-router-dom';

import { FormsPage } from 'pages/FieldNotebook/FormsPage/FormsPage';
import FormFillPage from 'pages/FieldNotebook/FormFillPage/FormFillPage';
import { AnswersPage } from 'pages/FieldNotebook/AnswersPage/AnswersPage';
import EditAnswerPage from 'pages/FieldNotebook/FormFillPage/EditAnswerPage';
import { Archives } from 'pages/FieldNotebook/ArchivesPage/ArchivesPage';
import { Historics } from 'pages/FieldNotebook/HistoricsPage/HistoricsPage';
import { FormsNoteSummary } from 'pages/FieldNotebook/FormsNoteSummary/FormsNoteSummary';
import { HistoricNoteSummary } from 'pages/FieldNotebook/HistoricNoteSummary/HistoricNoteSummary';

export default function RoutesIndex() {
  return (
    <Routes>
      <Route path='/field-notebook/archives' element={<Archives />} />
      <Route path='/field-notebook/historic' element={<Historics />} />
      <Route path='/field-notebook/archives/:archive_id/forms/:is_historic' element={<FormsPage />} />
      <Route path='/field-notebook/archives/:archive_id/forms' element={<FormsPage />} />
      <Route path='/field-notebook/archives/:archive_id/forms/:form_id/fill' element={<FormFillPage />} />
      <Route path='/field-notebook/editing/:is_historic' element={<EditAnswerPage />} />
      <Route path='/field-notebook/editing' element={<EditAnswerPage />} />
      <Route path='/field-notebook/forms/summary' element={<FormsNoteSummary />} />
      <Route path='/field-notebook/archives/:archive_id/forms/:form_id/answers' element={<AnswersPage />} />
      <Route path='/field-notebook/historic/summary' element={<HistoricNoteSummary />} />
      <Route path='*' element={<Navigate to='/field-notebook/archives' />} />
    </Routes>
  );
}
