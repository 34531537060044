import { Component, ProviderProps } from 'providers';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ReduxState } from 'store';
import { UserState } from 'store/states/user/types';

const workspaceKey = process.env.REACT_APP_SURVICATE_WORKSPACE_KEY;

export function SurvicateProvider({ children }: ProviderProps) {
  const { properties } = useSelector<ReduxState, UserState>((state) => state.user);

  const {
    email, name, last_name, phone, organization, products,
  } = properties;

  useEffect(() => {
    const s = document.createElement('script');
    s.src = `https://survey.survicate.com/workspaces/${workspaceKey}/web_surveys.js`;
    s.async = true;
    const e = document.getElementsByTagName('script')[0];
    e.parentNode?.insertBefore(s, e);

    (function (opts) {
      opts.traits = {
        email: email || ' ',
        first_name: name || ' ',
        last_name: last_name || ' ',
        phone: phone || ' ',
        organization: organization || ' ',
        created_at: new Date(),
        active_products: products || [],
      };
    }(window._sva = window._sva || {}));
  }, [email, name, last_name, phone, organization, products]);

  return (
    <Component.Provider value={null}>
      {children}
    </Component.Provider>
  );
}
