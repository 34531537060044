import { TextField as TextFieldMUI, styled } from '@mui/material';
import React, { useState } from 'react';
import { themeOptions } from 'assets/styles/theme';

interface TextFieldProps {
  pattern?: RegExp;
  inputComponent?: any;
  error?: boolean;
  errorText?: string;
  shrink?: boolean;
  helperText?: string;
  maxLength?: number;
  label?: string;
  setValue: (value: string) => void;
  type?: string;
  defaultValue: string;
  isExtraAnnotation?: boolean;
  variant?: string;
}

const StyledTextField = styled(TextFieldMUI)`
  width: 100%;
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type=number] {
    -moz-appearance: textfield;
  }
  input {
    &:invalid {
      color: ${themeOptions.palette.error.main}
    }
  }
`;

export default function TextField(
  {
    pattern,
    inputComponent,
    shrink,
    error,
    errorText = '',
    helperText,
    maxLength,
    label = '',
    setValue,
    type = 'text',
    defaultValue,
    isExtraAnnotation,
    variant = 'outlined',
  }: TextFieldProps,
) {
  const [errorMessage, setErrorMessage] = useState('');
  const handleChangeValue = (value : string) => {
    if (value === ' ' || value === '') {
      setValue('');
    } else if (!pattern || value.match(pattern)) {
      setValue(value);
      setErrorMessage('');
    } else {
      setErrorMessage(errorText);
    }
  };

  return (
    <StyledTextField
      type={type}
      helperText={helperText}
      error={errorMessage.length !== 0}
      errorText={errorMessage}
      id='outlined-basic'
      label={label}
      variant={variant}
      value={defaultValue}
      multiline
      rows={isExtraAnnotation ? 6 : 1}
      onChange={(e: React.FormEvent<HTMLInputElement>) => handleChangeValue(e.currentTarget.value)}
      InputProps={{
        pattern,
        shrink,
        maxLength,
        inputComponent,
      }}
    />
  );
}
