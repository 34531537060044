import { Container as ContainerMUI } from '@mui/material';
import { themeOptions } from 'assets/styles/theme';
import styled from 'styled-components';

export const Container = styled(ContainerMUI)`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
`;

export const BoxesContainer = styled(ContainerMUI)`
  width: 100%;
  padding: 0px;

  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
`;

export const Paragraph = styled.p`
  color: ${themeOptions.palette.text.secondary};
  font-size: 13px;
`;
export const ErrorText = styled.p`
  color: ${themeOptions.palette.error.main};
  font-size: 10px;
  font-weight: 500;
`;
