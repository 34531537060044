import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import EditIcon from '@mui/icons-material/Edit';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { ReduxState } from 'store';
import {
  Answer, Field, FillData, FormState,
} from 'store/states/forms/types';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { ImperativeModalHandler, Modal } from 'components/Modal/Modal';
import { sendUpdateAnswerRequest } from 'store/states/historics/actions';
import { Archive } from 'store/states/archives/types';
import { HistoricState } from 'store/states/historics/types';
import { verifyRules } from 'utils/fieldNotebookUtils/verifyRules';
import { editAnswer } from 'store/states/forms/actions';
import { Button } from '../components/Button/Button';
import { Container } from '../components/Container/Container';
import {
  Title, SubTitle, SummaryCard, TitleCard, ButtonGroup, NoteGroup,
} from './styles';
import { CancelNote } from '../components/CancelNote/CancelNote';
import { FieldBoxComponent } from '../components/FieldBoxComponent/FieldBoxComponent';

export function HistoricNoteSummary() {
  const dispatch = useDispatch();
  const { name: title, id: archiveId } = useSelector<ReduxState, Archive>((state) => state.archives.selectedArchive);
  const { answers } = useSelector<ReduxState, FillData>((state) => state.forms.fillData);
  const { updateLoading, updateSuccess } = useSelector<ReduxState, HistoricState>((state) => state.historics);
  const { selectedForm } = useSelector<ReduxState, FormState>((state) => state.forms);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const saveEditResponse = () => {
    dispatch(sendUpdateAnswerRequest({ successMessage: t('annotation_edit_message_success'), errorMessage: t('connection_failed_message') }));
  };

  const handleClickEdit = (field: Field) => {
    dispatch(editAnswer(field));
    navigate('/field-notebook/editing');
  };

  useEffect(() => {
    if (updateSuccess && !updateLoading) {
      navigate(`/field-notebook/archives/${archiveId}/forms/${selectedForm.form_id}/answers`);
    }
  }, [updateSuccess, updateLoading, navigate, selectedForm.form_id, dispatch, t, archiveId]);

  const cancelModalRef = useRef<ImperativeModalHandler>(null);

  const handleOpenCancelNoteModal = () => cancelModalRef.current?.open();
  const handleCloseCancelNoteModal = () => cancelModalRef.current?.close();

  return (
    <Container>

      <Title>{title}</Title>
      <SubTitle>
        {t('summary_title')}
        <span>
          {t('summary_edit')}
          (
          <EditIcon />
          )
        </span>
        {t('summary_edit_text')}
      </SubTitle>

      <SummaryCard>
        <TitleCard>{t('summary_resume')}</TitleCard>

        <NoteGroup>
          {selectedForm.fields.map((field, index) => {
            const answer = answers.find((a) => a.field_id === field.field_id) as Answer;
            const isFieldValid = verifyRules(field, answers);
            return isFieldValid && <FieldBoxComponent field={field} index={index} answer={answer} handleEdit={() => handleClickEdit(field)} />;
          })}
        </NoteGroup>
      </SummaryCard>

      <ButtonGroup>
        <Button onClick={handleOpenCancelNoteModal}>
          {t('cancel')}
        </Button>
        <Button
          variant='contained'
          rightIcon={<CheckCircleIcon />}
          onClick={saveEditResponse}
          isLoading={updateLoading}
        >
          {t('update_annotation')}
        </Button>
      </ButtonGroup>

      <Modal ref={cancelModalRef} title={t('cancel')} subtitle={t('cancel_annotation_descripton')}>
        <CancelNote closeModal={handleCloseCancelNoteModal} type='historic' />
      </Modal>
    </Container>
  );
}
