import { Typography, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { themeOptions } from 'assets/styles/theme';

export const Title = styled(Typography)`
  color: ${themeOptions.palette?.text?.primary};
  font-size: 21px;
  font-weight: ${themeOptions.typography.fontWeightMedium};
  margin: 16px 0 25px;
`;

export const ImageContainer = styled('img')`
  max-width: 260px;
  border-radius: 6px;
  width: 100%;
`;

export const SubTitle = styled(Typography)`
  color: ${themeOptions.palette?.text?.primary};
  font-size: 14px;
  font-weight: ${themeOptions.typography.fontWeightRegular};
  margin-bottom: 68px;

  span {
    display: inline-flex;
    font-weight: ${themeOptions.typography.fontWeightBold};
  }

  svg {
    margin-top: -3px;
    color: ${themeOptions.palette.primary.main};
  }
`;

export const SummaryCard = styled(Box)`
  margin: -45px auto 40px;
  max-width: 370px;
  padding: 16px;
  background: #FFFFFF;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.15);
  border-radius: 5px;

  hr {
    margin: 16px 0;

    &:last-child {
      display: none;
    }
  }
`;

export const TitleCard = styled(Typography)`
  color: ${themeOptions.palette?.text?.primary};
  font-size: 21px;
  font-weight: ${themeOptions.typography.fontWeightMedium};
  margin-bottom: 16px;
`;

export const NoteGroup = styled(Box)`
  max-height: 300px;
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 5px;
    padding-left: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #ccc;
  }
`;

export const ButtonGroup = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 16px;
`;
