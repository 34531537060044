import axios from 'axios';

const baseURL = process.env.REACT_APP_FIELD_NOTEBOOK_API_BASE_URL;

const headers = {
  Accept: 'application/json, text/javascript',
  platform: 'web',
};

export const fieldNotebookClient = axios.create({ baseURL, headers });
