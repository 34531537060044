import { ProgressBarContainer } from './styles';

interface ProgressBarProps {
  totalSteps: number;
  step: number;
}

export function ProgressBar({ step, totalSteps }: ProgressBarProps) {
  return (
    <ProgressBarContainer
      variant='progress'
      steps={totalSteps + 1}
      position='static'
      activeStep={step}
      sx={{ maxWidth: 500, flexGrow: 1 }}
    />
  );
}
