/* eslint-disable no-param-reassign */
import { Reducer } from 'redux';
import produce from 'immer';
import { SnackbarActions, SnackbarState } from './types';

const INITIAL_STATE: SnackbarState = {
  isOpen: false,
  message: '',
  typeMessage: 'success',
};

const snackbar: Reducer<SnackbarState> = (state = INITIAL_STATE, action) => produce(state, (draft) => {
  switch (action.type) {
    case SnackbarActions.SHOW_SNACKBAR: {
      const { message, typeMessage } = action.payload;
      draft.isOpen = true;
      draft.message = message;
      draft.typeMessage = typeMessage;
      break;
    }
    case SnackbarActions.CLOSE_SNACKBAR: {
      draft.isOpen = false;
      break;
    }
    default:
      return state;
  }
});

export default snackbar;
