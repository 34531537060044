import { Tabs } from '@mui/material';
import { styled } from '@mui/material/styles';
import { themeOptions } from 'assets/styles/theme';

export const TabsContainer = styled(Tabs)`
  margin-bottom: 16px;

  .MuiTab-textColorPrimary {
    color: ${themeOptions.palette?.text?.primary};
    padding: 0;
    
    &:first-child {
      margin-right: 24px;
    }
  }

  .MuiTabs-indicator {
    border: 1px solid ${themeOptions.palette.primary.main};
  }
`;
