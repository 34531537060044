/* eslint-disable no-param-reassign */
import { Reducer } from 'redux';
import produce from 'immer';
import { User, UserState, UserActionType } from './types';

const INITIAL_STATE : UserState = {
  jwt: '',
  properties: {} as unknown as User,
  language: '',
  isLoading: false,
  error: false,
  success: false,
};

const userReducer: Reducer<UserState> = (state = INITIAL_STATE, action) => produce(state, (draft) => {
  switch (action.type) {
    case UserActionType.SET_USER_PROPERTIES: {
      const { user } = action.payload;

      draft.properties = user;
      draft.success = true;
      draft.isLoading = false;
      break;
    }
    case UserActionType.SET_TOKEN: {
      const { token } = action.payload;
      draft.jwt = token;
      break;
    }
    case UserActionType.SET_USER_LANGUAGE: {
      const { language } = action.payload;
      draft.language = language;
      break;
    }
    default: {
      return state;
    }
  }
});

export default userReducer;
