import styled from 'styled-components';

export const ButtonContainer = styled.div`
  width: 90%;

  display: flex;
  justify-content: flex-end;
`;

export const Buttons = styled.div`
  width: 30px;
  margin-top: 12px;
`;
