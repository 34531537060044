import { Box } from '@mui/material';
import { themeOptions } from 'assets/styles/theme';
import styled from 'styled-components';

export const Note = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #141414;

  svg {
    color: ${themeOptions.palette.primary.main};
    cursor: pointer;
    transition: filter 0.5s;

    &:hover {
      filter: brightness(0.8);
    }
  }
`;
