import { themeOptions } from 'assets/styles/theme';
import styled from 'styled-components';

export const Container = styled.label`
  display: flex;
  flex-direction: column;
  align-items: center;

  > button {
    display: none;
  }
`;

interface LabelProps {
  marginTop: number;
}

export const Label = styled.label<LabelProps>`
  margin-top: ${(props) => props.marginTop}px;

  input {
    display: none;
  }

  > span {
    display: inline-flex;
    width: 370px;
  }
`;

interface ImagePreviewProps {
  image: string;
}

export const ImagePreview = styled.div<ImagePreviewProps>`
  width: 370px;
  height: 140px;
  padding: 4px;

  background-image: url(${(props) => props.image});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin-bottom: 13px;

  display: flex;
  align-items: flex-start;
  justify-content: flex-end;

  img {
    cursor: pointer;
    transition: 0.2s filter;

    &:hover {
      filter: brightness(0.9);
    }
  }

  button {
    display: none;
  }
`;

export const Buttons = styled.div`
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const ProgressContainer = styled.div`
  height: 80px;
  padding-top: 80px;

  display: flex;
  align-items: center;
  justify-content: center;
`;
export const MaxSizeTextError = styled.div`
  height: 80px;
  padding-top: 80px;

  color: ${themeOptions.palette.error.main};

  display: flex;
  align-items: center;
  justify-content: center;
`;
