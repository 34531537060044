import styled from 'styled-components';
import { themeOptions } from 'assets/styles/theme';

export const ModalContainer = styled.div`
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.6);
  overflow: scroll;
`;

export const ModalContainerPaper = styled.div`
  background: ${themeOptions.palette.common.white};
  padding: 24px 24px 20px;
  border-radius: 5px;

  @media (max-width: 850px) {
    width: 100%;
    height: 100%;
    overflow: scroll;
  }

  h6 {
    padding: 16px 16px 12px;
    font-weight: 500;
    color: ${themeOptions.palette.text.primary};
  }

  p {
    padding: 0px 16px 12px;
    font-size: 1 rem;
    color: ${themeOptions.palette.grey['300']};
    font-weight: 400;
    line-height: 1.5;
  }
`;

export const ModalTitleContainer = styled.div``;

export const ModalContainerTop = styled.div`
  display: flex;
  justify-content: flex-end;

  svg {
    cursor: pointer;
  }
`;

export const ModalContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  align-items: center;

  svg {
    margin: 0 auto;
  }
`;
