import { themeOptions } from 'assets/styles/theme';
import styled from 'styled-components';

interface ModalOpenerProps {
  image: string;
}

export const ModalOpener = styled.div<ModalOpenerProps>`
  height: 140px;
  max-width: 255px;

  border: 1px solid ${themeOptions.palette.primary.light};
  border-radius: 8px;
  cursor: pointer;

  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%), url(${(props) => props.image});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  display: flex;
  align-items: flex-end;
  justify-content: center;
  

  transition: 0.2s filter;

  &:hover {
    filter: brightness(0.85);
  }

  div {
    width: 100%;
    padding: 0 5px 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    color: ${themeOptions.palette.common.white};

    p {
      font-size: 11px;
    }

    img {
      width: 17px;
      height: 17px;
    }
  }
`;
