import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  clearHistoric,
  getHistoricsRequest,
  setFilterOptions,
} from 'store/states/historics/actions';

export const SearchBar: React.FC = () => {
  const dispatch = useDispatch();

  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    const getData = setTimeout(() => {
      dispatch(setFilterOptions({ search: searchText }));
      dispatch(clearHistoric());
      dispatch(getHistoricsRequest());
    }, 500);

    return () => clearTimeout(getData);
  }, [searchText]);

  const handleChange = useCallback((text: string) => {
    setSearchText(text);
  }, []);

  const handleClearSearch = useCallback(() => {
    setSearchText('');
  }, []);

  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-end',
        marginRight: '32px',
        padding: '0 22px',
      }}
    >
      <TextField
        variant='standard'
        value={searchText}
        onChange={(
          e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
        ) => handleChange(e.currentTarget.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position='start'>
              <SearchIcon color='inherit' />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position='end'>
              <IconButton onClick={handleClearSearch}>
                <ClearIcon color='disabled' />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </div>
  );
};
