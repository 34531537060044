import * as React from 'react';
import NumberFormat from 'react-number-format';

interface CustomProps {
  onChange: (event: { currentTarget: { name: string; value: string } }) => void;
  name: string;
}

export const MaskInput = React.forwardRef<NumberFormat<any>, CustomProps>(
  (props, ref) => {
    const { onChange, ...other } = props;
    return (
      <NumberFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values: any) => {
          onChange({
            currentTarget: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        allowNegative
        isNumericString
        decimalScale={16}
      />
    );
  },
);
