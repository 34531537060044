import React, { useRef, useState, useEffect } from 'react';
import { CreateOutlined, CheckCircleOutline } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { ImperativeModalHandler, Modal } from 'components/Modal/Modal';
import { Button } from 'pages/FieldNotebook/components/Button/Button';
import {
  SignatureContainer,
  SignatureDraw,
  SignatureDrawToolbar,
  SignatureModalButtons,
  SignaturePreview,
} from './styles';
import { SignatureMenu } from './SignatureMenu';

interface ISignature {
  setValue: (value: string) => void;
  defaultValue: string;
}

export default function Signature({ defaultValue, setValue }: ISignature) {
  const [userSignature, setUserSignature] = useState<any>(null);
  const [signaturePreview, setSignaturePreview] = useState('');

  useEffect(() => {
    setSignaturePreview(defaultValue);
  }, [defaultValue]);
  const { t } = useTranslation();

  const signatureModalRef = useRef<ImperativeModalHandler>(null);

  const handleOpenSignatureModal = () => signatureModalRef.current?.open();
  const handleCloseSignatureModal = () => signatureModalRef.current?.close();

  const saveUserSignature = async (signature: any) => {
    setValue(signature?.getDataURL());
    handleCloseSignatureModal();
  };

  return (
    <>
      <SignatureContainer>
        {signaturePreview && <SignaturePreview signature={defaultValue} />}

        <Button
          variant='outlined'
          color='primary'
          onClick={() => handleOpenSignatureModal()}
          rightIcon={<CreateOutlined />}
        >
          {t('signature_modal_title')}
        </Button>
      </SignatureContainer>
      <Modal
        ref={signatureModalRef}
        title={t('signature_modal_title')}
        subtitle={t('signature_modal_subtitle')}
      >
        <SignatureDraw
          ref={(userSign: any) => setUserSignature(userSign)}
          brushRadius={3}
          hideGrid
        />
        <SignatureDrawToolbar>
          <SignatureMenu signature={userSignature} />
        </SignatureDrawToolbar>
        <SignatureModalButtons>
          <Button onClick={() => handleCloseSignatureModal()}>
            {t('back')}
          </Button>
          <Button
            onClick={() => saveUserSignature(userSignature)}
            variant='contained'
            rightIcon={<CheckCircleOutline />}
          >
            {t('finalize')}
          </Button>
        </SignatureModalButtons>
      </Modal>
    </>
  );
}
