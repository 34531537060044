import { ComponentPropsWithoutRef, ReactNode } from 'react';
import { Box } from '@mui/material';

import { CardContainer, CardTitle, CardContents } from './styles';

interface CardProps extends ComponentPropsWithoutRef<'div'> {
  title: string;
  subTitle: string;
  colortitle: string;
  icon?: ReactNode;
}

export function Card({
  title, subTitle, colortitle, icon, ...rest
}: CardProps) {
  return (
    <CardContainer {...rest}>
      <CardContents component='section'>
        <CardTitle component='h2' colortitle={colortitle}>
          {title}
        </CardTitle>
        <p>
          {subTitle}
        </p>
      </CardContents>
      <Box>
        {icon}
      </Box>
    </CardContainer>
  );
}
