import {
  FilterOptions, Historic, HistoricAnswer, HistoricsActionType,
} from './types';

export const getHistoricsRequest = () => ({
  type: HistoricsActionType.GET_HISTORICS_REQUEST,
});

export const getAllHistoricsRequest = () => ({
  type: HistoricsActionType.GET_ALL_HISTORICS_REQUEST,
});

export const getHistoricsSuccess = (newHistorics: Historic[], hasNextPage: boolean) => (
  {
    type: HistoricsActionType.GET_HISTORICS_SUCCESS,
    payload: { newHistorics, hasNextPage },
  }
);

export const getHistoricsFailure = () => (
  {
    type: HistoricsActionType.GET_HISTORICS_FAILURE,
  }
);

export const setSelectedHistoric = (historic: Historic) => (
  {
    type: HistoricsActionType.SET_HISTORIC_SELECTED,
    payload: { historic },
  }
);

export const clearHistoric = () => (
  {
    type: HistoricsActionType.CLEAR_HISTORIC,
  }
);

interface sendUpdateAnswerRequestParams {
  successMessage: string;
  errorMessage: string;
}

export const sendUpdateAnswerRequest = ({ successMessage, errorMessage }:sendUpdateAnswerRequestParams) => (
  {
    type: HistoricsActionType.UPDATE_ANSWERS_REQUEST,
    payload: { successMessage, errorMessage },
  }
);

export const sendUpdateAnswerSuccess = () => (
  {
    type: HistoricsActionType.UPDATE_ANSWERS_SUCCESS,
  }
);

export const sendUpdateAnswerError = () => (
  {
    type: HistoricsActionType.UPDATE_ANSWERS_ERROR,
  }
);

export const updateHistorics = (historicAnswers: HistoricAnswer[]) => (
  {
    type: HistoricsActionType.UPDATE_HISTORICS,
    payload: { historicAnswers },
  }
);

export const setFilterOptions = (filterOptions: FilterOptions) => (
  {
    type: HistoricsActionType.SET_FILTER_OPTIONS,
    payload: { filterOptions },
  }
);
