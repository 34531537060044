/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { Button as MUIButton } from '@mui/material';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import DeleteIcon from '@mui/icons-material/Delete';
import { useEffect, useRef, useState } from 'react';
import { ImperativeModalHandler, Modal } from 'components/Modal/Modal';
import { Button } from 'pages/FieldNotebook/components/Button/Button';
import cancelIcon from 'assets/icons/cancel.png';
import { getCompressImageBase64 } from 'utils/shared/image';
import { useTranslation } from 'react-i18next';
import { ProgressBar } from 'components/ProgressBar/ProgressBar';
import {
  Container,
  Label,
  ImagePreview,
  Buttons,
  ProgressContainer,
  MaxSizeTextError,
} from './styles';
// Max Size is 5MB
const MAX_IMAGE_SIZE = 5127304;

interface UploadImageProps {
  setValue: (value: string) => void;
  defaultValue: string;
}

export default function UploadImage({
  setValue,
  defaultValue,
}: UploadImageProps) {
  const { t } = useTranslation();
  const [urlImagePreview, setUrlImagePreview] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  const [percentage, setPercentage] = useState(0);

  useEffect(() => {
    setUrlImagePreview(defaultValue);
  }, [defaultValue]);

  const modalRef = useRef<ImperativeModalHandler>(null);

  const handleOpenModal = () => modalRef.current?.open();

  const handleCloseModal = () => modalRef.current?.close();

  const handleRemoveImage = () => {
    handleCloseModal();
    setUrlImagePreview('');
    setValue('');
  };

  const handleImagePreview = async (e: any) => {
    const { target } = e;
    handleRemoveImage();
    if (target.files[0].size > MAX_IMAGE_SIZE) {
      setError(true);
    } else {
      setError(false);
      setIsLoading(true);
      const url = URL.createObjectURL(target.files[0]);

      const base64 = await getCompressImageBase64(
        target.files[0],
        setPercentage,
      );

      setValue(base64);
      setIsLoading(false);
      setUrlImagePreview(url);
    }
  };

  return (
    <Container>
      {error && (
        <MaxSizeTextError>{t('max_image_size_error_text')}</MaxSizeTextError>
      )}
      {urlImagePreview && (
        <ImagePreview image={defaultValue}>
          <button type='button' />
          <img src={cancelIcon} alt='remove icon' onClick={handleOpenModal} />
        </ImagePreview>
      )}
      {isLoading && (
        <ProgressContainer>
          <ProgressBar step={percentage} totalSteps={100} />
        </ProgressContainer>
      )}
      <button type='button' />
      <Label
        htmlFor='contained-button-file'
        marginTop={!urlImagePreview ? 70 : 0}
      >
        <input
          type='file'
          accept='image/*'
          id='contained-button-file'
          onChange={handleImagePreview}
        />
        <MUIButton
          variant='outlined'
          component='span'
          endIcon={<FileUploadIcon />}
        >
          {t('send_image')}
        </MUIButton>
      </Label>
      <Modal ref={modalRef} title={t('remove_image_title')} subtitle={t('remove_image_message')}>
        <Buttons>
          <Button onClick={handleCloseModal}>{t('cancel')}</Button>
          <Button
            variant='contained'
            color='error'
            rightIcon={<DeleteIcon />}
            onClick={handleRemoveImage}
          >
            {t('remove_image')}
          </Button>
        </Buttons>
      </Modal>
    </Container>
  );
}
